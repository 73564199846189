import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'hooks/useAppContext';
import { ScaffoldingStyled } from './ScaffoldingStyled';

// TODO: wire this properly
let isProd = true;
try {
  isProd = process.env.NODE_ENV !== 'development';
} catch {
  /*not empty, I swear*/
}
export const GMS_REACT_MESSAGE_SIGNATURE =
  'idonate-gms-react-communication-pool';
const SCAFFOLDING_LOCATION_BASE = '/scaffolding/edit/';

// this handles subrouting for scaffolding editor
export const Scaffolding = () => {
  const { envConfig, selectedOrganization, selectedCampaignId } =
    useAppContext();
  //
  const { embedId: passedEmbedId } = useParams();
  const navigate = useNavigate();
  const [scaffoldingPath, setScaffoldingPath] = useState(
    SCAFFOLDING_LOCATION_BASE
  );
  const actualLocationRef = useRef<string>();
  const baseUrl = useMemo(() => {
    const localBaseUrl = envConfig.localScaffoldingUrl
      ? envConfig[
          isProd ? 'buildScaffoldingUrl' : 'localScaffoldingUrl'
        ].replace(/\/*$/g, '')
      : undefined;
    return localBaseUrl;
  }, [envConfig]);

  useEffect(() => {
    const newPath = `${SCAFFOLDING_LOCATION_BASE}${
      passedEmbedId || ''
    }?organization_id=${
      selectedOrganization.id || ''
    }&campaign_id=${selectedCampaignId}`;
    if (actualLocationRef.current !== newPath) {
      setScaffoldingPath(newPath);
    }
  }, [passedEmbedId, selectedOrganization, selectedCampaignId]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { data } = event || {};
      if (data.signature === GMS_REACT_MESSAGE_SIGNATURE) {
        const { pathname, search, redirect } = data.data.location || {};
        if (redirect) {
          window.location.href = redirect;
          return;
        }
        actualLocationRef.current = `${pathname || ''}${search || ''}`;
        const localpath = String(pathname || '').replace(
          SCAFFOLDING_LOCATION_BASE,
          '/scaffolding/'
        );
        navigate(localpath, { replace: true });
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [navigate]);

  return (
    <ScaffoldingStyled>
      {baseUrl ? (
        <iframe
          id="scaffolding"
          title="scaffolding"
          src={baseUrl + (scaffoldingPath || '')}
          allow="clipboard-read; clipboard-write"
        />
      ) : (
        <>no base url in env!</>
      )}
    </ScaffoldingStyled>
  );
};
