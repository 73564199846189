/*
  Api calls related to Scaffolding,
  limited in scope to provide templates
  create new form instance
  and send use off to the editor.
  Scaffolding package is expected to take care of the rest
*/
import {
  DefDocGetManyControllerResponseType,
  DefDocInstanceControllerPostPayload,
  DefDocInstanceControllerResponse
} from 'shared';
import auth0Client from './auth0Client';

const getScaffoldingBaseUrl = () => {
  const url = window.envVars.scaffoldingApiBase;
  if (!url) {
    throw new Error('Unable to resolve base url');
  }
  return url;
};
const getAuthToken = async () => {
  const authClient = await auth0Client;
  const claims = await authClient.getIdTokenClaims();
  // eslint-disable-next-line no-underscore-dangle
  const token = claims.__raw;
  return token;
};

export const createScaffoldingForm = async (
  defdocId: string,
  {
    name,
    description,
    baseVersion,
    organizationId,
    campaignId,
    isPublished = false
  }: {
    baseVersion: number;
    organizationId: string;
    campaignId: string;
    name?: string;
    description?: string;
    isPublished?: boolean;
  }
): Promise<DefDocInstanceControllerResponse> => {
  const response = await fetch(`${getScaffoldingBaseUrl()}instance`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAuthToken()}`
    },
    body: JSON.stringify({
      defdoc_id: defdocId,
      defdoc_patch: {
        name,
        description,
        defdoc_id: defdocId,
        patch: { rfc6902: [] },
        base_defdoc_version: baseVersion,
        organization_id: organizationId
      },
      embed_definition: {
        name,
        organization_id: organizationId,
        campaign_id: campaignId,
        is_enabled: isPublished
      }
    } as DefDocInstanceControllerPostPayload)
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error('error getting scaffolding templates');
  }

  return response.json();
};

export const getScaffoldingTemplates = async () => {
  const response = await fetch(
    `${getScaffoldingBaseUrl()}defdoc?${new URLSearchParams({
      doc_type: 'template'
    })}`,
    {
      headers: {
        Authorization: `Bearer ${await getAuthToken()}`
      }
    }
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error('error getting scaffolding templates');
  }

  return (await response.json()) as DefDocGetManyControllerResponseType;
};
