import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { EmptyStateTextDisplay } from 'components';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import TemplatePreviewCard from 'components/gms/TemplatePreviewCard';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useGivingFormTemplates } from 'queries/UseGivingForms';
import editsRepositoryApi from 'services/editsRepositoryService';
import './GivingFormTemplates.scss';

export const GivingFormTemplates = (): JSX.Element => {
  const editType = 'givingForm';

  const { selectedOrganization } = useAppContext();
  const [pushAlert] = useAlerts();

  const { id: organizationId } = selectedOrganization;
  const { data: templates } = useGivingFormTemplates({
    organizationId,
    includeGlobals: false,
    includeSharedTemplates: true
  });
  const [allGivingFormEdits, setAllGivingFormEdits] = useState<
    Array<{ id: string; name: string; editsSavedTime: string }>
  >([]);

  const getAllGivingFormEditsIds = async () => {
    try {
      const givingFormEdits = await editsRepositoryApi.getAllEdits(editType);
      setAllGivingFormEdits(givingFormEdits);
    } catch (e) {
      pushAlert({
        title: 'There was an error loading your local edits.',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    getAllGivingFormEditsIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unpublishedChangesById = (formId: string) => {
    const gfEdit = allGivingFormEdits.find(({ id }) => id === formId);
    return !!gfEdit;
  };

  return (
    <div className="giving-form-templates">
      <FeatureHeader titleProps="Giving Form Templates" />
      <Box
        className="templates-container"
        padding={7}
        paddingTop={11}
        display="flex"
      >
        {templates?.map((template) => (
          <TemplatePreviewCard
            templateOrgId={template.organizationId}
            templateName={template.name}
            templateId={template.embedInstanceId}
            templateDescription={template.templateDescription}
            key={template.embedInstanceId}
            showHoverButtons
            hasUnpublishedChanges={unpublishedChangesById(
              template.embedInstanceId
            )}
          />
        ))}
        {(!templates || !templates.length) && (
          <EmptyStateTextDisplay
            heading="You don’t currently have any saved templates."
            subheading="Start by creating a template from an existing giving form."
          />
        )}
      </Box>
    </div>
  );
};
