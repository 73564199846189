import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DEFAULT_ORGANIZATION } from 'constants/organizationConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { ScaffoldingPreview } from 'assets/svg/ScaffoldingPreview';
import { EmptyStateTextDisplay } from 'components';
import { CreateGivingFormWithHostedPageDialog } from 'components/gms/CreateGivingFormWithHostedPageDialog';
import NewGivingFormDialog from 'components/gms/NewGivingFormDialog';
import NewScaffoldingFormDialog from 'components/gms/NewScaffoldingFormDialog';
import TemplatePreviewCard from 'components/gms/TemplatePreviewCard';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { OrganizationRole, getRoleInt } from 'hooks/useRenderIfRole';
import { useGivingFormTemplates } from 'queries/UseGivingForms';
import { useUser } from 'queries/UseUsers';
import editsRepositoryApi from 'services/editsRepositoryService';
import { getScaffoldingTemplates } from 'services/scaffoldingService';
import { GivingForm } from 'services/types';
import './Templates.scss';

type TemplatesProps = {
  activeTab: string;
};

// Display template type that will work for both Scaffolding and Giving Forms
type DisplayTemplate = Pick<
  GivingForm,
  'organizationId' | 'name' | 'embedInstanceId' | 'templateDescription'
> &
  Partial<Pick<GivingForm, 'config'>> & {
    type?: 'scaffolding' | 'giving';
    preview?: ReactNode;
  };

export const Templates = ({ activeTab }: TemplatesProps) => {
  const editType = 'givingForm';
  const [scaffoldingTemplates, setScaffoldingTemplates] = useState<
    DisplayTemplate[]
  >([]);
  const { selectedOrganization, organizationInfo } = useAppContext();
  const [pushAlert] = useAlerts();
  const navigate = useNavigate();
  const user = useUser();
  const userOrganizationRole = useMemo(() => {
    const matchedOrgRole = user.data?.organizations.find(
      (org) => org.id === selectedOrganization.id
    );
    return matchedOrgRole.role;
  }, [user, selectedOrganization]);
  const { id: organizationId } = selectedOrganization;
  const { data: templates } = useGivingFormTemplates({
    organizationId,
    includeGlobals: true
  });
  const [allGivingFormEdits, setAllGivingFormEdits] = useState<
    Array<{ id: string; name: string; editsSavedTime: string }>
  >([]);
  const [showNewGivingFormDialog, setShowNewGivingFormDialog] = useState<
    'scaffolding' | 'giving' | 'null'
  >(null);
  const [
    showCreateGivingFormWithHpDialog,
    setShowCreateGivingFormWithHpDialog
  ] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const { campaignId } = useParams();
  const { selectedCampaignId, setSelectedCampaignId } = useAppContext();
  useEffect(() => {
    if (selectedCampaignId !== campaignId) {
      setSelectedCampaignId(campaignId);
    }
  }, [campaignId, selectedCampaignId]);
  useEffect(() => {
    let killed = false;
    (async () => {
      try {
        const givingFormEdits = await editsRepositoryApi.getAllEdits(editType);
        if (killed) return;
        setAllGivingFormEdits(givingFormEdits);
      } catch (e) {
        if (killed) return;
        pushAlert({
          title: 'There was an error loading your local edits.',
          severity: 'error'
        });
      }
    })();
    return () => {
      killed = true;
    };
  }, [setAllGivingFormEdits, pushAlert]);

  const unpublishedChangesById = (formId: string) => {
    const gfEdit = allGivingFormEdits.find(({ id }) => id === formId);
    return !!gfEdit;
  };

  const onTemplateClick = (template: DisplayTemplate) => {
    if (
      getRoleInt(selectedOrganization.role) >=
      getRoleInt(OrganizationRole.Editor)
    ) {
      //
      setSelectedTemplateId(template.embedInstanceId);
      // If the template has a Hosted Page config then show unique HP modal
      if (template.config.hostedPageConfig) {
        setShowCreateGivingFormWithHpDialog(true);
      } else {
        setShowNewGivingFormDialog('giving');
      }
    }
  };

  useEffect(
    function getScaffoldingTemplatesOnLoad() {
      let killed = false;
      if (!organizationInfo?.features?.scaffolding) {
        setScaffoldingTemplates([]);
        return;
      }
      (async function () {
        const newScaffoldingTemplates: DisplayTemplate[] = [];
        if (userOrganizationRole === 'SuperAdmin') {
          newScaffoldingTemplates.push({
            type: 'scaffolding',
            name: 'Freeform Scaffolding',
            templateDescription:
              "Enjoy the free and untamed wonders spin wild, driven to create an ultimate giving form. `God's Mode` entry point, Super Admins only.",
            organizationId: selectedOrganization.id,
            embedInstanceId: ' '
          });
        }
        const sts = await getScaffoldingTemplates();
        // map them to state templates
        sts.items?.forEach((t) => {
          newScaffoldingTemplates.push({
            type: 'scaffolding',
            name: t.name,
            templateDescription: t.description,
            organizationId: t.organization_id,
            embedInstanceId: `${t.id}--${t.version}`
          });
        });
        if (killed) return;
        setScaffoldingTemplates(newScaffoldingTemplates);
      })();
      return () => {
        killed = true;
      };
    },
    [userOrganizationRole, organizationInfo]
  );

  const onScaffoldingTemplateClick = useCallback(
    (template: DisplayTemplate) => {
      // kick it to scaffolding root
      if (!template.embedInstanceId.trim()) {
        navigate(`/scaffolding/${template.embedInstanceId || ''}`);
      } else {
        setSelectedTemplateId(template.embedInstanceId);
        setShowNewGivingFormDialog('scaffolding');
      }
    },
    [navigate, setSelectedTemplateId, setShowNewGivingFormDialog]
  );

  const renderTemplates = (templatesArray: DisplayTemplate[]) => {
    const uniqueSet = new Set();
    const templatesToDisplay = templatesArray.filter(
      (template: DisplayTemplate) => {
        if (uniqueSet.has(template.embedInstanceId)) {
          return false;
        }
        uniqueSet.add(template.embedInstanceId);
        return activeTab === 'globalTemplates'
          ? template.organizationId === DEFAULT_ORGANIZATION
          : template.organizationId !== DEFAULT_ORGANIZATION;
      }
    );
    return templatesToDisplay.map((template: DisplayTemplate) => (
      <TemplatePreviewCard
        templateOrgId={template.organizationId}
        templateName={template.name}
        templateId={template.embedInstanceId}
        templateDescription={template.templateDescription}
        onTemplateClick={() =>
          template.type === 'scaffolding'
            ? onScaffoldingTemplateClick(template)
            : onTemplateClick(template)
        }
        key={template.embedInstanceId}
        isGlobalTemplate={activeTab === 'globalTemplates'}
        hasUnpublishedChanges={unpublishedChangesById(template.embedInstanceId)}
        preview={
          template.type === 'scaffolding' ? <ScaffoldingPreview /> : null
        }
      />
    ));
  };

  return (
    <>
      <Box
        className="templates-container"
        padding={7}
        paddingTop={12}
        display="flex"
      >
        {scaffoldingTemplates && renderTemplates(scaffoldingTemplates)}
        {templates && renderTemplates(templates)}
        {!scaffoldingTemplates.length && !templates?.length ? (
          <EmptyStateTextDisplay
            heading="You don’t currently have any saved templates."
            subheading="Start by creating a template from an existing giving form."
          />
        ) : null}
      </Box>
      <NewGivingFormDialog
        open={showNewGivingFormDialog === 'giving'}
        onClose={() => setShowNewGivingFormDialog(null)}
        templateId={selectedTemplateId}
      />
      <NewScaffoldingFormDialog
        open={showNewGivingFormDialog === 'scaffolding'}
        onClose={() => setShowNewGivingFormDialog(null)}
        templateIdAndVersionString={selectedTemplateId}
      />
      <CreateGivingFormWithHostedPageDialog
        open={showCreateGivingFormWithHpDialog}
        onClose={() => setShowCreateGivingFormWithHpDialog(false)}
        templateId={selectedTemplateId}
        isDuplicate={false}
      />
    </>
  );
};
