import { Navigate, Route, Routes } from 'react-router-dom';
import { AppLayout } from 'components/gms/Layout';
import { useAppContext } from 'hooks/useAppContext';
import { useOrganization } from 'queries/UseOrganizationData';
import { AuthorizedRoute } from 'routes/AuthorizedRoute';
import { LEGACY_ROUTES } from 'routes/LegacyRoutes';
import { EmailType } from 'types/emailTypes/Email';
import {
  Campaign,
  Campaigns,
  EditGivingForm,
  NewCampaign
} from 'views/Campaigns';
import { GivingForms } from 'views/Campaigns/Campaign/GivingForms';
import { Dashboard } from 'views/Dashboard';
import { DesignationList, Designations } from 'views/Designations';
import { EditEmail } from 'views/Emails/EditEmail';
import { LegacyViewer } from 'views/LegacyViewer/LegacyViewer';
import { NotFound } from 'views/NotFound';
import { ReceiptManagement } from 'views/Receipts/ReceiptManagement';
import { Scaffolding } from 'views/Scaffolding/Scaffolding';
import { Strategy } from 'views/Strategy';
import {
  AbTestGivingForms,
  AbTestOverview,
  AbTests,
  CreateAbTest
} from 'views/Strategy/AbTests';
import { GivingFormTemplates } from 'views/Templates/GivingFormTemplates';
import { UserEdit } from 'views/Users/UserEdit';
import { Users } from 'views/Users/Users';

export const AppRoutes = (): JSX.Element => {
  const { selectedOrganization, setOrganizationInfo } = useAppContext();
  const { isLoading: orgInfoIsLoading } = useOrganization(
    selectedOrganization?.id,
    {
      onSuccess: (orgInfo) => {
        setOrganizationInfo(orgInfo);
      },
      enabled: !!selectedOrganization
    }
  );

  if (!selectedOrganization) return null;

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {/* Other unauthorized routes */}

        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="scaffolding">
          <Route index element={<Scaffolding />} />
          <Route path=":embedId" element={<Scaffolding />} />
        </Route>
        <Route path="campaigns">
          <Route
            index
            element={
              <AuthorizedRoute>
                <Campaigns />
              </AuthorizedRoute>
            }
          />
          <Route
            path="new"
            element={
              <AuthorizedRoute>
                <NewCampaign />
              </AuthorizedRoute>
            }
          />
          <Route
            path=":campaignId"
            element={
              <AuthorizedRoute>
                <Campaign />
              </AuthorizedRoute>
            }
          />
          <Route
            path=":campaignId/giving-forms"
            element={
              <AuthorizedRoute>
                <GivingForms />
              </AuthorizedRoute>
            }
          />
          <Route
            path=":campaignId/giving-forms/:givingFormId/edit"
            element={
              <AuthorizedRoute>
                <EditGivingForm />
              </AuthorizedRoute>
            }
          />
        </Route>
        <Route path="emails">
          <Route
            path="receipt/:emailId/edit"
            element={
              <AuthorizedRoute>
                <EditEmail type={EmailType.Receipt} />
              </AuthorizedRoute>
            }
          />
        </Route>

        <Route path="strategy">
          <Route
            index
            element={
              <AuthorizedRoute>
                <Strategy />
              </AuthorizedRoute>
            }
          />
          <Route path="ab-test">
            <Route
              index
              element={
                <AuthorizedRoute>
                  <AbTests />
                </AuthorizedRoute>
              }
            />
            {/* ab test home */}
            <Route
              path=":abTestId/overview"
              element={
                <AuthorizedRoute>
                  <AbTestOverview />
                </AuthorizedRoute>
              }
            />
            <Route
              path="giving-forms"
              element={
                <AuthorizedRoute>
                  <AbTestGivingForms />
                </AuthorizedRoute>
              }
            />

            <Route
              path="create/:givingFormId"
              element={
                <AuthorizedRoute>
                  <CreateAbTest />
                </AuthorizedRoute>
              }
            />
          </Route>
        </Route>

        <Route path="giving-form-templates">
          <Route
            index
            element={
              <AuthorizedRoute>
                <GivingFormTemplates />
              </AuthorizedRoute>
            }
          />
          <Route
            path=":givingFormTemplateId/edit"
            element={
              <AuthorizedRoute>
                <EditGivingForm />
              </AuthorizedRoute>
            }
          />
        </Route>

        <Route path="settings/designated-funds">
          <Route
            index
            element={
              <AuthorizedRoute>
                <DesignationList />
              </AuthorizedRoute>
            }
          />
          <Route
            path="new"
            element={
              <AuthorizedRoute>
                <Designations orgInfoIsLoading={orgInfoIsLoading} />
              </AuthorizedRoute>
            }
          />

          <Route
            path=":designationId/edit"
            element={
              <AuthorizedRoute>
                <Designations orgInfoIsLoading={orgInfoIsLoading} />
              </AuthorizedRoute>
            }
          />
        </Route>

        <Route path="receipts">
          <Route
            index
            element={
              <AuthorizedRoute>
                <ReceiptManagement />
              </AuthorizedRoute>
            }
          />
        </Route>

        <Route path="users">
          <Route
            index
            element={
              <AuthorizedRoute>
                <Users />
              </AuthorizedRoute>
            }
          />
          <Route
            path="new"
            element={
              <AuthorizedRoute>
                <UserEdit />
              </AuthorizedRoute>
            }
          />
          <Route
            path=":userId"
            element={
              <AuthorizedRoute>
                <UserEdit />
              </AuthorizedRoute>
            }
          />
        </Route>

        {LEGACY_ROUTES.map((route) => (
          <Route
            key={`${route.localPath}}`}
            path={route.localPath}
            element={
              <LegacyViewer
                featureHeader={
                  route?.featureHeader ? <route.featureHeader /> : undefined
                }
                backgroundHeader={route.backgroundHeader}
                minimumRole={route?.minimumRole}
              />
            }
          />
        ))}

        {/* ...other authorized routes */}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
