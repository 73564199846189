import prismatic from '@prismatic-io/embedded';

export const showPrismatic = async (organizationId: string) => {
  // Get the access token from sessionStorage
  const accessToken = window.sessionStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('No access token found in sessionStorage');
    return;
  }

  // Get the environment variables
  const { api2BaseUrl } = window.envVars;

  if (!api2BaseUrl) {
    console.error('api2BaseUrl not found in environment variables');
    return;
  }

  // Fetch the Prismatic authentication token
  const response = await fetch(
    `${api2BaseUrl}/org/integrations/prismatic/authenticate?organization_id=${organizationId}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch Prismatic token: ${response.statusText}`);
  }

  const data = await response.json();
  const {token} = data;

  // Authenticate with Prismatic
  await prismatic.authenticate({ token });

  // Show the marketplace as a popover
  prismatic.showMarketplace({
    usePopover: true,
    theme: 'LIGHT' // Or match your app's theme
  });
};
