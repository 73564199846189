import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useCreateGivingFormMutation,
  useValidateGivingFormName
} from 'queries/UseGivingForms';
import { GivingForm } from 'services';
import { createScaffoldingForm } from 'services/scaffoldingService';
import './NewScaffoldingFormDialog.scss';

type NewScaffoldingFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onCreate?: () => void;

  /**
   * the `id--version` formatter string
   */
  templateIdAndVersionString: string;
};

/**
 * This a a duplicate of the New Receipt form. I am sure the is a better way to do it.
 */
const NewScaffoldingFormDialog = ({
  open,
  onClose,
  onCreate,
  templateIdAndVersionString
}: NewScaffoldingFormDialogProps): JSX.Element => {
  const [formName, setFormName] = useState('');
  const [status, setStatus] = useState<'creating' | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [addAlert] = useAlerts();
  const [valid, setValid] = useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValid(true);
    setFormName(event.currentTarget.value);
  };
  const { selectedOrganization, selectedCampaignId } = useAppContext();

  const handleCreateGivingForm = async () => {
    try {
      if (!selectedOrganization.id || !selectedCampaignId) {
        throw new Error('Must select organization and campaign id');
      }
      setStatus('creating');
      const [id, version] = String(templateIdAndVersionString).split('--');
      const response = await createScaffoldingForm(id, {
        name: formName,
        baseVersion: Number(version) || -1,
        organizationId: selectedOrganization.id,
        campaignId: selectedCampaignId
      });
      //setSelectedGivingFormId(data.embedInstanceId);
      setFormName('');
      onCreate?.();
      setStatus(null);
      navigate(`/scaffolding/${response.embed_definition.id}`);
    } catch (e) {
      setStatus(null);
      console.error(
        '[NewScaffoldingFormDialog] Error creating scaffolding form:',
        String(e)
      );
      addAlert({
        title: 'Uh oh. Looks like there was an error creating the giving form.',
        severity: 'error'
      });
    }
  };

  return (
    <Dialog
      className="new-giving-form-dialog"
      label="new giving form name dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle onClick={(close) => close()} />
      <DialogContent>
        <DialogContentTitle>
          What are we calling this Giving Form?
        </DialogContentTitle>
        <DialogContentDescription>
          To start building your form, we need to give it a unique name.
        </DialogContentDescription>
        <TextField
          value={formName}
          placeholder="Enter Form Name"
          hiddenLabel
          onChange={handleChange}
          error={!valid}
          helperText={
            !valid
              ? `"${formName}" is already being used in this campaign. Please pick a unique name.`
              : undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <DialogActionButton variant="secondary" onClick={(close) => close()}>
          Cancel
        </DialogActionButton>
        <DialogActionButton
          variant="primary"
          onClick={handleCreateGivingForm}
          disabled={formName === '' || status === 'creating'}
        >
          {status === 'creating' ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Start Building'
          )}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewScaffoldingFormDialog;
