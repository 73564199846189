export const ScaffoldingPreview = () => (
  <svg
    className="scaffolding-preview-export"
    height="100%"
    width="100%"
    viewBox="0 0 896 501"
    preserveAspectRatio="xMidYMid slice"
  >
    <g fill="#409de2">
      <path d="M946.66,29.35c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM944.51,30.18c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M936.76,93.36c-12.69,28.11-25.95,56.64-39.47,84.32,0,0-1.09-2.15-1.09-2.15l-28.18-55.76-.33-.65.67-.25c20.23-7.48,48.55-18,68.4-25.52h0ZM934.75,94.13c-19.1,7.34-46.65,18.36-65.93,25.94,0,0,.34-.89.34-.89l29.17,55.25-2.15.04c12.49-27.21,25.27-53.54,38.57-80.34h0Z" />
      <path d="M879.51,55.08c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM877.36,55.91c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M867.5,119.88c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM869.65,119.06c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M898.78,174.66c6.93,16.87,13.66,33.8,20.17,50.66,0,0-3.63-2.49-3.63-2.49l-56.84-38.97-.97-.66,1.14-.24,38.61-7.99,1.51-.31h0ZM895.78,175.33l-36.9,8.61s.17-.9.17-.9l57.8,37.53s-2.02,1.65-2.02,1.65c-6.57-15.7-12.89-31.21-19.05-46.89h0Z" />
      <path d="M917.14,221.31c-5.68,16.21-11.78,32.9-18.21,48.82,0,0-.56,1.41-.56,1.41s-1.35-.63-1.35-.63l-48.42-22.74-1.77-.83c20.97-7.72,49.72-18.25,70.31-26.04h0ZM915.1,222.09c-19.13,7.39-46.58,18.44-65.87,26.1,0,0,.05-1.5.05-1.5l48.92,21.64s-1.91.78-1.91.78c5.88-16.04,12.16-31.28,18.82-47.02h0Z" />
      <path d="M860.1,183.51c-3.21,22.55-6.67,45.17-10.38,67.49,0,0-1.9-2.79-1.9-2.79l-44.87-66.1s-.62-.91-.62-.91c0,0,1.09.04,1.09.04l55.34,2.22s1.33.05,1.33.05h0ZM857.45,183.43l-54.05-1.09.47-.87,46.18,65.19-2.45.57c3.05-21.48,6.31-42.56,9.84-63.8h0Z" />
      <path d="M847.84,247.84c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM850,247.02c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M812.28,80.84c-11.78,16.48-24.21,33.48-36.76,49.37,0,0-.79,1.01-.79,1.01l-1-.76-30.11-22.83-1.24-.94c20.79-7.64,49.52-18.09,69.9-25.85h0ZM810.34,81.59c-19.18,7.37-46.6,18.47-65.92,26.15,0,0,.2-1.47.2-1.47l30.64,22.11s-1.79.25-1.79.25c11.96-16.28,24.11-31.38,36.87-47.05h0Z" />
      <path d="M774.88,128.37c10.54,17.98,21.02,36.59,30.76,54.97,0,0-2.38-.33-2.38-.33,0,0-45.48-6.38-45.48-6.38l-.79-.11s.27-.74.27-.74c5.26-14.02,12.59-33.58,17.62-47.41h0ZM774.15,130.44c-4.66,13.16-11.11,32.4-15.68,45.77,0,0-.52-.85-.52-.85l45.63,5.2s-1.23,1.81-1.23,1.81c-9.93-17.27-19.03-34.28-28.19-51.94h0Z" />
      <path d="M745.59,106.79c4.98,24.05,9.79,48.22,14.35,72.23,0,0-2.85-1.77-2.85-1.77l-96.88-60.08-1.55-.96s1.81-.2,1.81-.2l83.65-9.06s1.47-.16,1.47-.16h0ZM742.67,107.13l-82.06,10.13s.25-1.16.25-1.16l97.76,58.63s-2.22,1.55-2.22,1.55c-4.8-23.14-9.36-46.06-13.74-69.15h0Z" />
      <path d="M757.5,177c-5.43-23.89-10.65-48.59-14.99-72.67,0,0,2.27,1.72,2.27,1.72l30.09,22.86.38.29-.16.43c-5.24,13.98-12.62,33.56-17.59,47.37h0ZM758.19,175.04c4.7-13.18,11.13-32.44,15.72-45.83,0,0,.22.72.22.72l-30.66-22.09s1.74-1.11,1.74-1.11c5,22.94,9.03,45.2,12.98,68.31h0Z" />
      <path d="M801.39,181.71c-10.42-18.87-20.7-37.75-30.88-56.61,0,0,4.76,3.11,4.76,3.11l83.77,54.81,1.56,1.02-1.85-.07-55.34-2.18s-2.02-.08-2.02-.08h0ZM805.42,181.83l53.36,1.11s-.29.95-.29.95l-84.77-53.25s2.04-1.91,2.04-1.91c10.02,17.74,19.89,35.38,29.66,53.1h0Z" />
      <path d="M773.39,129.54c11.85-16.43,24.23-33.25,36.72-49.2,0,0,.85-1.09.85-1.09l1.12.76,56.98,38.76,1.94,1.32c-29.06,2.76-69,6.51-97.62,9.44h0ZM775.55,129.3c26.94-2.9,65.71-7.53,92.96-10.67,0,0-.38,1.54-.38,1.54,0,0-57.66-37.74-57.66-37.74l1.97-.33c-12.02,16.2-24.2,31.49-36.88,47.2h0Z" />
      <path d="M857.57,183.71c3.11-22.68,6.64-45.61,10.43-68.14,0,0,1.68,3.35,1.68,3.35l28.01,55.85.28.56s-.6.12-.6.12c-13.42,2.79-26.67,5.46-39.8,8.27h0ZM859.93,183.18c12.56-2.86,24.95-5.81,37.23-8.67l-.32.68-29.34-55.16,2.3-.38c-2.97,21.38-6.24,42.29-9.87,63.52h0Z" />
      <path d="M869.86,119.35c-3.04,22.16-6.38,44.57-9.98,66.62,0,0-1.92-1.25-1.92-1.25l-83.85-54.68-1.74-1.14s2.06-.2,2.06-.2l94.17-9.22s1.27-.12,1.27-.12h0ZM867.36,119.61c-31.14,3.44-62.06,7.05-92.78,10.53l.32-1.34,84.69,53.38s-2.29,1.04-2.29,1.04c3.08-21.37,6.45-42.35,10.07-63.61h0Z" />
      <path d="M898.53,268.96c.09,27.54-.15,55.57-.88,83.07,0,0-1.93-1.31-1.93-1.31l-56.98-38.77s-.79-.53-.79-.53l.77-.54c17.72-12.35,42.45-29.65,59.8-41.92h0ZM896.72,270.27c-16.61,11.91-40.48,29.49-57.23,41.68,0,0-.02-1.07-.02-1.07l57.67,37.73s-1.98,1.04-1.98,1.04c.08-26.66.68-52.75,1.56-79.38h0Z" />
      <path d="M897.53,349.26c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM895.38,350.08c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M840.11,310.88c-2.98,22.66-6.43,45.71-10.26,68.19,0,0-1.6-3.14-1.6-3.14l-23.47-46.1-.23-.44c12-6.26,23.89-12.31,35.55-18.51h0ZM838.15,311.95c-11.08,6.03-21.93,12.14-32.69,18.09,0,0,.2-.67.2-.67l24.65,45.48-2.18.36c2.92-21.36,6.27-42.08,10.01-63.27h0Z" />
      <path d="M828.19,375.8c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM830.34,374.98c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M887.7,413.24c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM885.55,414.06c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M877.88,477.22c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM875.72,478.04c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69,0,0-2.11.93-2.11.93,3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M820.55,438.97c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM818.4,439.79c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M808.54,503.77c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM810.69,502.94c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M806.4,329.65c-14.41,24.77-29.28,49.75-44.3,74.12,0,0-1.13-1.96-1.13-1.96l-43.03-74.74s-.61-1.07-.61-1.07c29.9,1.22,59.59,2.57,89.07,3.65h0ZM804.08,329.58c-28.74-.85-57.27-1.45-85.58-2.18,0,0,.61-1.02.61-1.02l44.19,74.06s-2.32-.01-2.32-.01c14.12-23.96,28.38-47.28,43.1-70.85h0Z" />
      <path d="M763.39,401c-3.14,22.58-6.65,45.37-10.4,67.81,0,0-1.78-3.04-1.78-3.04l-34.63-59.21s-.4-.69-.4-.69c0,0,.78-.08.78-.08l45.17-4.66,1.26-.13h0ZM760.9,401.28c-14.75,1.79-29.35,3.69-43.82,5.51,0,0,.38-.77.38-.77l35.95,58.42s-2.35.46-2.35.46c3-21.39,6.27-42.38,9.85-63.63h0Z" />
      <path d="M719.42,326.04c0,26.58-.24,53.72-1.01,80.27,0,0-.05,1.72-.05,1.72,0,0-1.65-.38-1.65-.38l-79.22-18.29-1.63-.38c24.85-18.65,59.18-44.43,83.55-62.94h0ZM717.66,327.39c-23.11,17.75-56.22,43.71-79.53,61.83,0,0-.3-1.37-.3-1.37l79.49,17.08-1.7,1.33c.22-26.49,1.02-52.4,2.04-78.87h0Z" />
      <path d="M715.7,406.43c.29-28.1.87-56.27,1.71-84.15,0,0,2.19,3.82,2.19,3.82l42.91,74.81s.33.58.33.58c0,0-.65.07-.65.07l-45.17,4.74-1.32.14h0ZM718.33,406.13l43.76-5.43s-.32.64-.32.64l-44.32-73.99,2.32-.61c-.22,26.67-.69,52.93-1.44,79.38h0Z" />
      <path d="M751.21,465.51c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM753.37,464.69c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M761.13,401.51c14.24-24.81,29.11-50.05,44.19-74.33,0,0,.96,1.88.96,1.88l23.62,46.02.36.7-.73.27c-20.22,7.47-48.58,17.94-68.41,25.46h0ZM763.1,400.75c19.13-7.35,46.64-18.39,65.94-26,0,0-.37.97-.37.97l-24.5-45.56s2.08.06,2.08.06c-14.03,24.02-28.3,47.02-43.15,70.52h0Z" />
      <path d="M818.36,439.79c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM820.52,438.96c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M830.38,374.99c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM828.23,375.81c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M661.43,115.92c2.75,27.57,5.21,55.64,7.16,83.24,0,0-2.13-1.45-2.13-1.45l-56.97-38.78-.69-.47.64-.51c13.23-10.62,38.82-31.33,51.99-42.02h0ZM659.69,117.35c-14.37,11.88-35.01,29.4-49.48,41.53,0,0-.05-.98-.05-.98l57.68,37.72s-1.94,1.15-1.94,1.15c-2.52-26.63-4.48-52.75-6.21-79.41h0Z" />
      <path d="M668.69,196.57c3.97,16.04,7.79,32.18,11.39,48.22,0,0-2.87-1.24-2.87-1.24l-93.89-40.61s-2.78-1.2-2.78-1.2c0,0,3.02-.18,3.02-.18l83.6-4.9,1.55-.09h0ZM665.62,196.78l-81.98,6.22.23-1.38,94.58,38.99s-2.18,1.84-2.18,1.84c-3.75-15.27-7.26-30.38-10.65-45.67h0Z" />
      <path d="M610.85,158.02c-8.25,14.97-17.04,30.46-26.06,44.97,0,0-.65,1.06-.65,1.06l-1.1-.51-40.71-18.63-1.9-.87c21-7.72,49.82-18.21,70.43-26.03h0ZM608.88,158.78c-19.17,7.39-46.59,18.47-65.9,26.16,0,0,.04-1.58.04-1.58l41.14,17.65-1.76.56c8.45-14.76,17.23-28.49,26.47-42.78h0Z" />
      <path d="M582.45,202.35c8.35-14.91,17.13-30.16,26.09-44.71,0,0,.83-1.36.83-1.36,0,0,1.29.88,1.29.88l56.95,38.81,2.01,1.37c-26,1.45-61.55,3.4-87.16,5h0ZM584.69,202.19c23.86-1.67,58.26-4.48,82.4-6.35,0,0-.4,1.5-.4,1.5l-57.7-37.69,2.12-.48c-8.52,14.71-17.26,28.68-26.42,43.02h0Z" />
      <path d="M678.86,241.67c-9.92,28.54-20.36,57.49-31.1,85.7,0,0-1.3-2.09-1.3-2.09l-34.95-56.22s-.4-.65-.4-.65c0,0,.7-.27.7-.27,19.84-7.76,47.59-18.68,67.05-26.47h0ZM676.84,242.49c-18.7,7.6-45.67,18.98-64.55,26.81,0,0,.3-.92.3-.92l35.94,55.59s-2.17.23-2.17.23c9.78-27.61,19.9-54.43,30.49-81.71h0Z" />
      <path d="M648.59,324.21c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM646.43,325.04c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M613.03,268.24c-10.33,28.17-21.18,56.8-32.37,84.61,0,0-1.29-1.77-1.29-1.77l-35.64-48.93-.48-.66.73-.35c20.43-9.66,49.04-23.23,69.05-32.9h0ZM611.1,269.19c-19.3,9.43-47.06,23.46-66.53,33.16,0,0,.25-1.01.25-1.01l36.51,48.28s-2.11.28-2.11.28c10.23-27.32,20.82-53.76,31.89-80.72h0Z" />
      <path d="M579.31,350.74c10.39-28.22,21.3-56.85,32.5-84.73,0,0,1.27,2.05,1.27,2.05l34.95,56.22s.41.67.41.67l-.72.27c-20.24,7.49-48.55,18.01-68.41,25.53h0ZM581.35,349.96c19.09-7.34,46.64-18.35,65.92-25.93,0,0-.31.94-.31.94l-35.94-55.59s2.17-.21,2.17-.21c-10.24,27.31-20.8,53.82-31.84,80.8h0Z" />
      <path d="M543.67,183.59c-2.78,21.45-5.85,43.42-9.37,64.75,0,0-.25,1.54-.25,1.54,0,0-1.5-.32-1.5-.32l-76.56-16.54-2.16-.47c26.76-14.52,63.56-34.48,89.84-48.95h0ZM541.73,184.67c-24.74,13.81-60.21,34.11-85.17,48.25,0,0-.23-1.52-.23-1.52l76.82,15.27-1.75,1.21c3.02-21.33,6.54-42.01,10.33-63.22h0Z" />
      <path d="M534.08,247.66c4.29,18.71,8.4,37.65,12.16,56.38,0,0-2.47-.9-2.47-.9l-78.31-28.64-1.75-.64,1.73-.64,67.39-25.09,1.24-.46h0ZM531.64,248.59l-65.7,25.89s-.02-1.28-.02-1.28l78.84,27.15-1.94,1.7c-4.03-17.9-7.69-35.53-11.18-53.46h0Z" />
      <path d="M457.69,232.12c3.6,15,7.01,30.05,10.21,45.01,0,0-3.02-2.07-3.02-2.07l-56.84-38.97s-1.45-1-1.45-1c0,0,1.74-.1,1.74-.1l47.82-2.79,1.54-.09h0ZM454.64,232.34l-46.22,3.86s.29-1.1.29-1.1c0,0,57.8,37.53,57.8,37.53,0,0-2.24,1.55-2.24,1.55-3.42-14.03-6.6-27.85-9.63-41.84h0Z" />
      <path d="M464.45,274.32c-3.66-14.62-7.18-29.47-10.33-44.14,0,0,2.35.51,2.35.51l76.53,16.7,2.49.54c-23.84,8.87-47.59,17.57-71.04,26.38h0ZM466.9,273.37c22.11-8.64,43.97-17.37,65.68-25.95,0,0,.12,1.43.12,1.43l-76.86-15.11s1.83-1.87,1.83-1.87c3.37,13.89,6.37,27.55,9.23,41.51h0Z" />
      <path d="M543.11,302.3c-4.32-18.61-8.44-37.54-12.2-56.24,0,0,2.3.62,2.3.62l79,21.34,1.86.5c-23.83,11.35-47.55,22.51-70.97,33.77h0ZM545.39,301.19c22.33-11.04,44.41-22.16,66.34-33.11l.13,1.33-79.39-19.85s1.82-1.74,1.82-1.74c4.02,17.84,7.63,35.43,11.09,53.38h0Z" />
      <path d="M532.04,248.85c2.77-22.03,5.94-44.68,9.65-66.56,0,0,1.5.68,1.5.68l40.72,18.6,1.09.5c-15.76,13.86-37.58,32.98-52.96,46.78h0ZM533.61,247.42c14.45-13.09,34.99-32.32,49.48-45.71,0,0,.2,1.27.2,1.27l-41.13-17.68,1.77-.97c-2.93,21.36-6.47,41.89-10.32,63.09h0Z" />
      <path d="M610.72,269.24c-10.13-23.15-20.1-46.45-29.77-69.65,0,0,3.2,1.38,3.2,1.38l93.91,40.57,1.29.56-1.3.51-66,26.1-1.33.53h0ZM613.35,268.18l64.26-26.63s0,1.07,0,1.07c0,0-94.56-39.03-94.56-39.03l1.85-1.86c9.74,22.2,19.17,44.21,28.46,66.45h0Z" />
      <path d="M584.52,201.45c10.19,22.89,20.23,46.18,29.83,69.29,0,0-2.65-.71-2.65-.71l-79.01-21.31s-1.11-.3-1.11-.3c17.78-15.78,35.49-31.35,52.93-46.97h0ZM582.7,203.1c-16.67,15.21-33.09,30.44-49.44,45.48,0,0-.26-1.05-.26-1.05l79.38,19.89s-1.58,1.84-1.58,1.84c-9.72-22.05-18.96-43.89-28.1-66.15h0Z" />
      <path d="M636.66,389.19c2.72-21.45,5.73-43.53,9.27-64.87,0,0,.21-1.28.21-1.28l1.27.05s71.01,2.7,71.01,2.7c0,0,2.42.09,2.42.09-25.08,18.8-59.61,44.62-84.19,63.31h0ZM638.37,387.88c23.15-17.77,56.2-43.75,79.51-61.92,0,0,.5,1.54.5,1.54l-71.05-1.5s1.48-1.23,1.48-1.23c-2.99,21.33-6.6,41.91-10.44,63.12h0Z" />
      <path d="M646.33,324.93c9.93-28.53,20.36-57.47,31.1-85.67,0,0,1.31,2.06,1.31,2.06l36.31,57.14.42.66-.73.27c-20.25,7.49-48.55,18.02-68.42,25.53h0ZM648.37,324.14c19.09-7.34,46.64-18.35,65.91-25.92,0,0-.3.93-.3.93l-37.31-56.49,2.18-.24c-9.79,27.61-19.9,54.44-30.48,81.73h0Z" />
      <path d="M716.83,326.57c-1.62-10.06-3.12-20.24-4.4-30.33,0,0,2.63.92,2.63.92l90.29,31.57s5.27,1.84,5.27,1.84c-.83-.08-91.03-3.86-93.8-4.01h0ZM719.91,326.68l85.22,2.02-.29,1.6-90.88-29.85,2.27-1.88c1.42,9.4,2.61,18.67,3.68,28.11h0Z" />
      <path d="M715.74,298.35c1.77,9.89,3.34,20.07,4.56,30.04,0,0-1.96-.08-1.96-.08l-71.01-2.93s-4.2-.17-4.2-.17l3.92-1.45c23.13-8.56,46.03-16.9,68.68-25.41h0ZM713.37,299.26c-22.16,8.67-44.02,17.46-65.7,26.06,0,0-.28-1.63-.28-1.63l71.06,1.27s-1.7,1.9-1.7,1.9c-1.44-9.26-2.46-18.28-3.37-27.61h0Z" />
      <path d="M676.25,242.2c-4.01-16.27-7.83-32.61-11.4-48.84,0,0,2.97,2.03,2.97,2.03l56.85,38.96,1.24.85-1.47.21-46.72,6.59-1.46.21h0ZM679.14,241.75l45.11-7.5s-.23,1.06-.23,1.06l-57.79-37.55,2.18-1.51c3.81,15.26,7.36,30.29,10.73,45.51h0Z" />
      <path d="M665.73,196.86c-2.56-27.63-4.92-55.46-6.96-83.03,0,0,2.4,1.49,2.4,1.49l96.91,60.04,1.36.84s-1.55.34-1.55.34l-90.86,20.03s-1.29.29-1.29.29h0ZM668.3,196.28l89.3-21s-.19,1.18-.19,1.18c0,0-97.73-58.68-97.73-58.68,0,0,2.19-1.35,2.19-1.35,2.42,26.74,4.54,53.16,6.44,79.84h0Z" />
      <path d="M723.45,235.43c10.7-20.26,21.96-41.21,33.57-60.98,0,0,.89.12.89.12l45.5,6.22s2.42.33,2.42.33c-24.53,16.11-58.37,38.19-82.37,54.3h0ZM725.15,234.27c22.64-15.25,54.87-37.66,77.63-53.33,0,0,.39,1.66.39,1.66l-45.61-5.35,1.35-.67c-10.81,19.82-22.05,38.38-33.76,57.7h0Z" />
      <path d="M758.82,175.67c-10.69,19.94-21.87,40.31-33.26,59.86,0,0-.77,1.32-.77,1.32l-1.24-.85-56.99-38.75s-1.57-1.07-1.57-1.07c27.9-6.05,66.37-14.36,93.82-20.52h0ZM756.7,176.16c-25.95,5.96-63.27,14.99-89.5,21.21,0,0,.27-1.46.27-1.46l57.66,37.75s-2.01.48-2.01.48c10.87-19.75,22-38.64,33.58-57.98h0Z" />
      <path d="M803.75,330.22c-10.53-14.26-20.87-28.64-30.98-43.01,0,0,4.03,1.44,4.03,1.44l62.35,22.2,1.09.39-1.01.53-34.12,17.75s-1.35.7-1.35.7h0ZM806.41,328.79l32.33-17.9.08.92-62.9-20.57s1.54-2.09,1.54-2.09c9.89,13.23,19.48,26.33,28.95,39.65h0Z" />
      <path d="M775.41,290.5c8.68-37.13,17.82-74.76,27.37-111.64,0,0,1.48,2.16,1.48,2.16l45.05,65.98.37.55s-.57.33-.57.33c-21.81,12.53-52.32,30.15-73.71,42.62h0ZM777.28,289.39c20.65-12.15,50.38-30.13,71.22-42.57,0,0-.19.87-.19.87l-46-65.32s2.13-.39,2.13-.39c-8.62,36.15-17.68,71.5-27.16,107.41h0Z" />
      <path d="M838.08,311.97c2.82-22.05,6.02-44.65,9.71-66.56,0,0,1.57.73,1.57.73l48.44,22.7,1.19.56c-18.13,12.61-43.17,29.99-60.91,42.56h0ZM839.84,310.71c16.65-11.95,40.46-29.55,57.2-41.79,0,0,.13,1.3.13,1.3l-48.9-21.67,1.85-1c-2.96,21.35-6.46,41.96-10.27,63.16h0Z" />
      <path d="M849.79,246.78c-2.78,21.45-5.87,43.43-9.42,64.76,0,0-.27,1.66-.27,1.66l-1.56-.55-62.43-21.97s-1.62-.57-1.62-.57c22.42-12.84,53.3-30.52,75.3-43.33h0ZM847.88,247.91c-20.67,12.21-50.29,30.2-71.13,42.71,0,0-.14-1.41-.14-1.41l62.83,20.81s-1.83,1.11-1.83,1.11c2.99-21.34,6.5-42.01,10.28-63.21h0Z" />
      <path d="M804.19,181.1c-8.61,37-17.69,74.55-27.21,111.29,0,0-1.5-1.61-1.5-1.61l-51.63-55.48-.55-.59s.66-.44.66-.44c23.73-15.64,56.97-37.63,80.22-53.17h0ZM802.41,182.3c-22.56,15.19-54.94,37.55-77.69,53.09,0,0,.11-1.02.11-1.02l52.41,54.75s-2.05.54-2.05.54c8.62-36.15,17.72-71.45,27.23-107.35h0Z" />
      <path d="M778.28,289.69c10.4,14.25,20.69,28.56,30.84,42.86,0,0-4.52-1.58-4.52-1.58l-90.29-31.59-2.39-.84s2.5-.33,2.5-.33l61.93-8.27s1.92-.26,1.92-.26h0ZM774.46,290.23l-59.86,9.17.11-1.17,90.88,29.84s-1.73,2.34-1.73,2.34c-9.94-13.4-19.71-26.74-29.4-40.19h0Z" />
      <path d="M725.58,234.65c-3.12,22.53-6.61,45.3-10.35,67.69,0,0-1.79-2.83-1.79-2.83l-36.18-57.22-.44-.69.8-.11c16.15-2.3,32.11-4.49,47.95-6.83h0ZM723.13,235.03c-15.27,2.45-30.38,5.02-45.35,7.49,0,0,.36-.8.36-.8l37.44,56.41s-2.34.5-2.34.5c3-21.38,6.28-42.34,9.88-63.59h0Z" />
      <path d="M713.27,298.98c3.08-22.34,6.5-44.92,10.18-67.12,0,0,1.88,2.04,1.88,2.04l51.48,55.63s.81.88.81.88c-21.62,2.89-43.08,5.65-64.35,8.58h0ZM715.73,298.62c20.37-3.04,40.53-6.22,60.54-9.29,0,0-.36,1.03-.36,1.03l-52.56-54.6s2.34-.73,2.34-.73c-3.03,21.37-6.34,42.33-9.96,63.59h0Z" />
      <path d="M865.86,542.02c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM868.01,541.19c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M875.69,478.04c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM877.84,477.21c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M945.03,451.49c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM942.87,452.31c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M885.51,414.06c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM887.67,413.23c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M895.56,350.36c-.1-27.99.2-56.48.99-84.41,0,0,2.01,3.02,2.01,3.02l34.22,51.49s.25.38.25.38c-12.63,9.95-25.17,19.68-37.47,29.52h0ZM897.28,348.97c11.78-9.57,23.31-19.18,34.79-28.61l-.1.66-35.31-50.75,2.1-.63c-.03,26.67-.59,52.72-1.48,79.34h0Z" />
      <path d="M933.33,319.98c7.85,23.46,15.47,47.33,22.65,70.95,0,0-2.91-1.99-2.91-1.99l-56.91-38.87-.56-.39.53-.42c12.53-9.87,24.96-19.54,37.2-29.3h0ZM931.45,321.51c-11.72,9.55-23.24,19.12-34.7,28.55,0,0-.04-.8-.04-.8l57.73,37.63-1.87,1.41c-7.41-22.3-14.36-44.33-21.13-66.79h0Z" />
      <path d="M954.85,387.51c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM952.7,388.33c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M914.75,222.2c-6.85-16.4-13.56-32.96-19.98-49.43,0,0,2.86.73,2.86.73l85.77,21.81s2.12.54,2.12.54l-2.04.76-67.39,25.09s-1.34.5-1.34.5h0ZM917.4,221.19l65.6-25.85s.08,1.3.08,1.3l-86.17-20.18s1.77-2.05,1.77-2.05c6.49,15.62,12.66,31.08,18.72,46.78h0Z" />
      <path d="M994.12,131.52c-2.88,21.99-6.06,44.42-9.67,66.3,0,0-1.57-.4-1.57-.4l-85.81-21.66-2.31-.58s2.17-.95,2.17-.95c28.75-12.57,68.97-30.19,97.19-42.71h0ZM992.05,132.46c-27.4,12.37-66.77,30.58-94.45,43.26,0,0-.14-1.53-.14-1.53l86.13,20.33s-1.84,1.23-1.84,1.23c3.04-21.33,6.54-42.07,10.29-63.29h0Z" />
      <path d="M448.53-20.9c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM446.37-20.08c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M438.84,43.02c3.15,15.27,6.09,30.82,8.71,46.17,0,0-2.28-.57-2.28-.57l-74.99-18.69-2.34-.58c23.8-8.85,47.5-17.53,70.89-26.33h0ZM436.42,43.96c-22.12,8.64-43.98,17.38-65.7,25.95l-.09-1.42,75.36,17.13s-1.87,1.78-1.87,1.78c-2.86-14.53-5.35-28.84-7.69-43.45h0Z" />
      <path d="M381.38,4.83c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM379.22,5.65c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M369.36,69.63c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM371.51,68.8c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M446.59,86.61c-8.88,28.88-18.22,58.29-27.95,86.88,0,0-1.41-.96-1.41-.96,0,0-57.02-38.7-57.02-38.7l-1.05-.71c25.96-13.74,61.96-32.8,87.43-46.51h0ZM444.7,87.64c-24.31,13.21-59.19,32.68-83.71,46.23,0,0,.06-1.3.06-1.3,0,0,57.62,37.8,57.62,37.8l-1.96.68c8.91-28.18,18.24-55.51,27.99-83.41h0Z" />
      <path d="M419.16,171.23c-3.08,22.4-6.51,45.07-10.22,67.33,0,0-1.84-2.3-1.84-2.3l-43.36-54.32-.61-.77.95-.17c18.55-3.3,36.89-6.45,55.08-9.78h0ZM416.76,171.69c-17.67,3.44-35.14,7.01-52.47,10.46,0,0,.34-.94.34-.94l44.5,53.39-2.32.63c3.01-21.37,6.31-42.3,9.94-63.54h0Z" />
      <path d="M361.81,132.75c1.62,16.87,2.99,34.05,4.02,50.95,0,0-2.1-.7-2.1-.7l-70.48-23.37s-2-.66-2-.66c23.7-8.82,47.29-17.44,70.56-26.21h0ZM359.49,133.65c-22.15,8.64-44.03,17.39-65.75,25.97,0,0-.04-1.39-.04-1.39,0,0,70.94,21.92,70.94,21.92l-1.96,1.54c-1.4-16.11-2.39-31.9-3.2-48.04h0Z" />
      <path d="M362.87,181.81c-1.53-17.13-2.84-34.4-3.85-51.45,0,0,2.41,1.65,2.41,1.65l56.88,38.92,1.29.88-1.53.27-53.88,9.5s-1.32.23-1.32.23h0ZM365.48,181.32c17.62-3.46,35.06-7.03,52.35-10.49l-.24,1.15-57.77-37.59s2.23-1.3,2.23-1.3c1.41,16.21,2.52,32.09,3.42,48.22h0Z" />
      <path d="M314.23,30.56c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM312.07,31.38c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M304.4,94.54c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM302.25,95.36c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69l-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M247.08,56.29c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM244.92,57.11c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M235.06,121.08c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM237.21,120.26c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69l2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M292.38,159.34c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM294.54,158.51c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M302.21,95.35c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM304.36,94.53c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M359.63,133.74c2.79-21.45,5.87-43.41,9.39-64.74,0,0,.26-1.57.26-1.57,0,0,1.52.38,1.52.38l75.02,18.54,2.07.51c-26.28,13.91-62.44,33.02-88.26,46.89h0ZM361.58,132.67c24.29-13.23,59.14-32.7,83.66-46.26,0,0,.2,1.5.2,1.5l-75.32-17.28,1.78-1.2c-3.02,21.33-6.53,42.02-10.31,63.23h0Z" />
      <path d="M371.55,68.81c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM369.4,69.63c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M199.58-45.95c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM197.43-45.12c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M189.76,18.03c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM187.6,18.86c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M132.43-20.22c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2L63.8,6.5l-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM130.28-19.39C111.21-12.02,83.7-1.02,64.44,6.57c0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69l-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M120.42,44.58c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM122.57,43.76c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M75.11-58.47c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2L6.48-31.75l-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM72.96-57.64c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24L64.95,4.78l-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M63.09,6.33c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77C114.83-12.97,81.07-.4,63.09,6.33h0ZM65.25,5.51c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M177.61,82.75c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM179.77,81.92c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M187.44,18.77c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM189.59,17.94c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69l2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M244.76,57.02c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM246.91,56.19c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24L254.92-6.23s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M256.78-7.78c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM254.62-6.95c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M311.91,31.29c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM314.06,30.47c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M379.06,5.56c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM381.21,4.74c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M391.08-59.24c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM388.92-58.41c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M323.93-33.51c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2L255.29-6.79l-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM321.77-32.68c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M406.84,235.51c3.16-22.55,6.66-45.29,10.4-67.71,0,0,1.81,2.96,1.81,2.96l37.29,61.01.45.74-.86.05-47.82,2.87s-1.28.08-1.28.08h0ZM409.39,235.33c15.64-1.22,31.13-2.55,46.48-3.8l-.4.8-38.61-60.18s2.38-.49,2.38-.49c-3.01,21.4-6.27,42.41-9.85,63.68h0Z" />
      <path d="M416.78,171.62c9.1-29.06,18.6-58.41,28.36-87.23,0,0,1.53,1.89,1.53,1.89l58.06,71.79.72.89s-1.13.16-1.13.16c-29.4,4.2-58.57,8.24-87.54,12.5h0ZM419.1,171.27c28.56-4.4,56.88-8.99,85.03-13.43,0,0-.41,1.06-.41,1.06l-59.13-70.92s2.31-.43,2.31-.43c-8.96,28.18-18.19,55.79-27.8,83.72h0Z" />
      <path d="M454.97,232.56c15.57-24.9,31.78-50.43,48.21-74.76,0,0,.7-1.05.7-1.05l1.03.7,37.98,25.82,1.16.79c-26.43,14.32-63.19,34.17-89.08,48.51h0ZM456.77,231.55c24.8-13.79,60.25-34.14,85.24-48.32,0,0-.06,1.45-.06,1.45l-38.42-25.16,1.73-.35c-15.78,24.74-31.84,48.23-48.49,72.39h0Z" />
      <path d="M505.32,158.32c-16.06,25.43-32.69,51.17-49.46,76.11,0,0-1.05-1.7-1.05-1.7l-37.46-60.9s-.57-.93-.57-.93c26.27-3.67,62.69-8.79,88.54-12.58h0ZM503.16,158.65c-24.63,3.73-60.18,9.55-85.1,13.5,0,0,.49-1.07.49-1.07l38.44,60.29s-2.17-.02-2.17-.02c15.82-24.68,31.81-48.5,48.33-72.69h0Z" />
      <path d="M444.16,87.27c-3.07-15.63-5.94-31.34-8.57-46.92,0,0,2.82,1.93,2.82,1.93l56.85,38.96,1.39.95s-1.66.16-1.66.16l-49.37,4.78-1.46.14h0ZM447.06,86.95l47.8-5.82-.27,1.11-57.79-37.55s2.24-1.47,2.24-1.47c2.91,14.67,5.56,29.11,8.03,43.73h0Z" />
      <path d="M436.29,43.51c3.11-22.28,6.52-44.76,10.15-66.92,0,0,1.97,1.87,1.97,1.87l66.16,62.72,1.18,1.12s-1.61.02-1.61.02l-76.54,1.18-1.31.02h0ZM438.89,43.44l75.22-2.46-.43,1.14-67.23-61.57,2.41-.84c-3.06,21.39-6.38,42.43-9.96,63.72h0Z" />
      <path d="M493.88,82.13c5.91-13.65,12.27-27.81,18.93-41.12,0,0,.56-1.14.56-1.14l1.18.37,47.77,14.94,2.44.76c-21.18,7.78-50.09,18.31-70.88,26.19h0ZM495.88,81.36c19.16-7.4,46.57-18.47,65.88-26.16,0,0,.06,1.63.06,1.63l-48.12-13.78s1.74-.77,1.74-.77c-6.13,13.45-12.66,25.99-19.56,39.08h0Z" />
      <path d="M515.33,41.63c-6.26,14.14-12.93,28.53-19.8,42.37,0,0-1.45-.99-1.45-.99l-56.92-38.85-2.12-1.45c23.99-.29,56.63-.65,80.29-1.09h0ZM512.99,41.69c-21.8.61-53.3,1.84-75.36,2.61,0,0,.43-1.48.43-1.48l57.73,37.65s-2.23.61-2.23.61c6.19-13.43,12.65-26.25,19.44-39.39h0Z" />
      <path d="M541.34,184.38c5.36-30.94,11.12-62.24,17.23-93,0,0,1.68,2.18,1.68,2.18l49.78,64.32s.49.64.49.64l-.74.28c-23.01,8.61-45.83,17.01-68.44,25.59h0ZM543.48,183.56c22.17-8.59,44.1-17.32,65.88-25.86,0,0-.25.91-.25.91,0,0-50.83-63.5-50.83-63.5,0,0,2.22-.54,2.22-.54-5.31,29.92-10.98,59.25-17.02,89h0Z" />
      <path d="M557.9,94.31c.73-13.69,1.66-27.49,2.87-41.07,0,0,2.16,1.48,2.16,1.48l56.87,38.94,1.96,1.34-2.36-.03-60.13-.65s-1.37-.01-1.37-.01h0ZM560.6,94.31c19.78-.18,39.38-.51,58.79-.77,0,0-.4,1.32-.4,1.32l-57.78-37.57s2.39-1.17,2.39-1.17c-.76,12.87-1.77,25.42-3.01,38.19h0Z" />
      <path d="M608.72,158.93c2.8-22.08,6-44.74,9.72-66.66,0,0,1.56.87,1.56.87l40.64,22.67.97.54c-15.74,12.61-37.52,30.01-52.89,42.58h0ZM610.37,157.57c14.42-11.92,34.98-29.47,49.45-41.68,0,0,.12,1.23.12,1.23l-41.14-21.75,1.86-.92c-2.93,21.36-6.45,41.92-10.28,63.12h0Z" />
      <path d="M620.71,94.27c-3.18,22.48-6.61,45.05-10.3,67.31,0,0-1.92-2.49-1.92-2.49l-49.68-64.4-.74-.95s1.19.01,1.19.01c0,0,60.13.52,60.13.52h1.32ZM618.1,94.27l-58.83.64s.46-.94.46-.94l50.92,63.42s-2.43.64-2.43.64c3.05-21.46,6.33-42.52,9.88-63.76h0Z" />
      <path d="M563.21,55.58c-.53,13.44-1.34,27.24-2.53,40.64,0,0-1.71-.35-1.71-.35l-64.2-13.32s-2.8-.58-2.8-.58l2.67-.98c20.31-7.48,48.66-17.9,68.58-25.39h0ZM561.01,56.43c-19.08,7.41-46.53,18.43-65.79,26.07,0,0-.14-1.57-.14-1.57l64.49,11.86-1.86,1.42c.73-12.76,1.92-25.07,3.3-37.78h0Z" />
      <path d="M559.96,93.52c-5.09,30.63-10.6,62-16.73,92.42,0,0-1.46-1-1.46-1l-37.97-25.83s-.72-.49-.72-.49l.56-.64c16.66-18.98,40.11-45.64,56.32-64.46h0ZM558.62,95.1c-15.73,18.34-38.06,45.16-53.82,63.88,0,0-.16-1.13-.16-1.13l38.43,25.15s-1.81.76-1.81.76c5.22-29.96,11.11-58.91,17.35-88.65h0Z" />
      <path d="M496.34,81.6c3.27,25.58,6.34,51.3,9.17,76.73l.49,4.42-2.78-3.45-57.94-71.89-.53-.65.83-.08,49.35-4.94,1.41-.14h0ZM493.52,81.9l-47.85,5.67s.3-.74.3-.74l59.25,70.82-2.29.98c-3.38-25.73-6.51-51.15-9.41-76.73h0Z" />
      <path d="M503.5,159.32c-3.72-26.18-7.22-52.97-10.08-79.24,0,0,1.77.36,1.77.36l64.23,13.16,1.26.26c-17.02,19.4-40.55,46.24-57.17,65.46h0ZM504.91,157.66c15.69-18.34,38.04-45.13,53.79-63.82,0,0,.42,1.21.42,1.21l-64.45-12.02s1.56-1.45,1.56-1.45c3.41,25.51,6.12,50.48,8.68,76.08h0Z" />
      <path d="M659.2,116.59c14.58-10.15,29.97-20.67,45.13-29.97,0,0,.69.33.69.33l39.25,18.98s2.98,1.44,2.98,1.44c-26.29,2.71-62.15,6.25-88.04,9.22h0ZM661.24,116.35c23.97-2.79,58.3-7.38,82.52-10.49,0,0-.29,1.77-.29,1.77l-39.66-18.11,1.37-.09c-14.52,9.7-28.86,18.06-43.94,26.92h0Z" />
      <path d="M703.58,89c-3.26-28.79-6.24-58.1-8.67-86.94,0,0,2.14,1.46,2.14,1.46l56.97,38.77s.65.44.65.44c0,0-.57.52-.57.52-14.99,13.49-35.88,32.37-50.53,45.75h0ZM705.22,87.49c13.97-12.92,34.01-31.95,48.06-45.13,0,0,.07.95.07.95l-57.67-37.73,1.92-1.15c3,27.84,5.43,55.16,7.62,83.05h0Z" />
      <path d="M742.77,107.19c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM744.93,106.37c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M754.5,42.07c-2.77,22.04-5.94,44.71-9.66,66.59,0,0-1.51-.73-1.51-.73l-39.24-18.99s-1.03-.5-1.03-.5c15.31-13.73,36.51-32.69,51.44-46.36h0ZM752.94,43.51c-14.02,12.97-33.96,32.02-48.01,45.29,0,0-.19-1.25-.19-1.25l39.66,18.09s-1.78.95-1.78.95c2.92-21.36,6.47-41.88,10.32-63.08h0Z" />
      <path d="M695.28,4.96c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM697.43,4.13c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M809.92,81.46c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM812.08,80.64c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M819.75,17.48c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM821.9,16.66c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M877.07,55.73c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM879.23,54.91c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M889.09-9.07c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM886.94-8.24c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M831.77-47.32c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM829.61-46.49c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M821.94,16.66c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM819.79,17.49c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M764.62-21.59c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM762.46-20.76c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M752.6,43.21c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM754.75,42.39c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M707.42-59.76c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM705.27-58.93c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M697.65,4.15c3.04,27.88,5.89,56.11,8.37,84.03,0,0,.2,2.24.2,2.24,0,0-2.13-.68-2.13-.68l-118.37-37.69-1.91-.61s1.84-.76,1.84-.76l110.83-46.05s1.15-.48,1.15-.48h0ZM695.37,5.12s-109.14,46.91-109.14,46.91l-.07-1.37,118.82,36.24-1.92,1.56c-2.89-27.88-5.38-55.43-7.69-83.35h0Z" />
      <path d="M705.63,88.25c-14.61,10.15-30,20.64-45.18,29.97,0,0-.72-.4-.72-.4l-40.65-22.65-2.79-1.55c26.67-1.56,63.07-3.54,89.34-5.36h0ZM703.57,88.4c-24.38,1.74-59.34,4.82-83.98,6.87,0,0,.38-1.74.38-1.74l41.13,21.76s-1.45.05-1.45.05c14.52-9.69,28.86-18.08,43.93-26.95h0Z" />
      <path d="M587.82,51.01c12.65,16.25,25.1,32.53,37.44,48.81,0,0-6.44-4.42-6.44-4.42l-56.8-39.03s-.66-.45-.66-.45c0,0,.77-.14.77-.14l23.81-4.41,1.87-.35h0ZM584.1,51.75l-21.83,4.66.12-.59,57.84,37.47-1.69,1.83c-11.66-14.5-23.1-28.88-34.43-43.37h0Z" />
      <path d="M617.4,94.48c-12.03-15.36-23.97-30.77-35.8-46.18,0,0,4.83,1.54,4.83,1.54l118.31,37.86s3.26,1.04,3.26,1.04c0,0-3.41.22-3.41.22l-85.06,5.38-2.13.13h0ZM621.63,94.18l82.87-6.5-.15,1.26-118.87-36.07,1.72-2.49c11.15,14.05,22.18,28.06,33.14,42.14l1.29,1.66h0Z" />
      <path d="M572.86-8.6c-2.71,21.46-5.76,43.57-9.35,64.89,0,0-.25,1.51-.25,1.51l-1.44-.45-47.8-14.86-1.4-.44c17.93-15.01,42.72-35.71,60.24-50.66h0ZM571.25-7.21c-16.47,14.2-39.94,35.02-56.47,49.54,0,0-.29-1.37-.29-1.37l48.1,13.86s-1.69,1.07-1.69,1.07c2.94-21.35,6.51-41.89,10.35-63.1h0Z" />
      <path d="M515.85-46.57c.21,30.33.11,61-.33,91.29,0,0-2.15-2.03-2.15-2.03l-66.2-62.67-.67-.63.86-.32c23.01-8.61,45.85-17.05,68.49-25.63h0ZM513.59-45.7c-22.13,8.59-44.04,17.28-65.83,25.83l.19-.95,67.19,61.61s-2.19.94-2.19.94c-.13-29.29.11-58.17.63-87.43h0Z" />
      <path d="M513.41,42.45c-.37-30.14-.41-60.82.08-90.92,0,0,1.9,1.3,1.9,1.3l56.99,38.74s.72.49.72.49l-.66.55c-17.49,14.68-41.94,35.27-59.05,49.85h0ZM515.07,41.02c16.43-14.15,39.99-34.96,56.53-49.4,0,0,.06,1.04.06,1.04l-57.65-37.76,1.94-1.03c.18,29.27-.21,57.92-.88,87.15h0Z" />
      <path d="M561,56.32c2.97-21.41,6.32-43.12,9.91-64.38,0,0,.97-5.79.97-5.79-.15-.89,14.02,63.61,14.39,65.13,0,0,.07.33.07.33,0,0-.32.06-.32.06l-23.81,4.42-1.2.22h0ZM563.38,55.84c7.6-1.58,15.09-3.22,22.5-4.8,0,0-.25.39-.25.39l-14.71-59.06,2.23-.09c-2.95,21.39-6.17,42.32-9.77,63.56h0Z" />
      <path d="M584.96,51.77c17.24-28.68,35.06-57.91,53.11-86.08,0,0,.73-1.14.73-1.14l1.11.75,57.06,38.65,1.28.87c-33.59,13.86-80.29,33.09-113.28,46.96h0ZM586.89,50.95c31.72-13.42,77.24-33.27,109.28-47.08,0,0-.14,1.45-.14,1.45,0,0-57.59-37.85-57.59-37.85l1.83-.39c-17.44,28.49-35.14,55.9-53.39,83.87h0Z" />
      <path d="M640.18-34c-17.74,29.48-36.15,59.36-54.74,88.27,0,0-.6-2.65-.6-2.65l-13.41-59.36s-.12-.53-.12-.53l.5-.19c20.21-7.48,48.55-18,68.37-25.53h0ZM638.21-33.24c-19.11,7.33-46.67,18.35-65.96,25.93,0,0,.38-.72.38-.72l14.43,59.12s-2.07-.34-2.07-.34c17.4-28.53,35-56.02,53.23-83.99h0Z" />
      <path d="M409.42,235.2c-6.74,16.26-13.93,33.01-21.42,48.92,0,0-.62,1.32-.62,1.32l-1.27-.64-45.24-22.74-1.7-.85c20.94-7.71,49.7-18.22,70.25-26.01h0ZM407.4,235.98c-19.14,7.39-46.58,18.45-65.88,26.11,0,0,.07-1.5.07-1.5l45.75,21.7s-1.89.68-1.89.68c6.93-16.07,14.24-31.27,21.96-46.99h0Z" />
      <path d="M387.63,282.87c1.17,27.54,2.03,55.58,2.4,83.11,0,0-2.01-1.37-2.01-1.37l-56.97-38.78-.75-.51.72-.53c16.78-12.36,40.19-29.66,56.61-41.94h0ZM385.84,284.22c-15.69,11.89-38.25,29.44-54.06,41.59,0,0-.03-1.04-.03-1.04l57.67,37.73-1.97,1.09c-.98-26.64-1.43-52.74-1.62-79.37h0Z" />
      <path d="M342.32,260.92c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM340.17,261.74c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M330.52,325.97c2.81-22.06,6.01-44.68,9.72-66.59,0,0,1.56.79,1.56.79l45.25,22.71s1.1.55,1.1.55c-17.15,12.6-40.86,29.98-57.63,42.54h0ZM332.23,324.67c15.74-11.93,38.22-29.5,54.03-41.72,0,0,.12,1.27.12,1.27l-45.73-21.73s1.85-.97,1.85-.97c-2.95,21.35-6.46,41.94-10.28,63.14h0Z" />
      <path d="M389.82,363.15c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM387.66,363.97c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M379.99,427.13c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM377.84,427.96c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M322.67,388.88c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM320.51,389.7c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M310.65,453.68c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM312.8,452.85c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M275.17,286.65c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM273.02,287.47c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69l-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M265.34,350.63c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM263.19,351.45c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M208.02,312.38c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM205.87,313.2c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M196.01,377.18c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM198.16,376.35c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M253.33,415.43c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM255.48,414.6c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M263.15,351.45c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM265.31,350.62c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M320.48,389.7c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM322.63,388.87c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M332.49,324.9c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM330.34,325.72c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M370.16,491.11c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM368.01,491.94c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M140.87,338.11c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM138.72,338.93c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M131.05,402.09c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM128.89,402.91c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M73.72,363.84c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM71.57,364.66c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M61.71,428.63c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM63.86,427.81c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M121.22,466.07c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM119.07,466.89c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M54.07,491.8c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM51.92,492.62c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M42.05,556.6c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM44.21,555.77c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M6.57,389.56c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM4.42,390.39c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69l-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M-15.27,518.34c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-13.12,517.52c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M-5.44,454.36c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-3.29,453.54c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M51.88,492.62c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM54.03,491.79c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M63.9,427.82c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM61.74,428.64c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M109.08,530.78c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM111.23,529.96c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M118.9,466.8c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM121.06,465.98c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M128.73,402.82c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM130.88,402c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M186.05,441.07c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM188.21,440.25c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M198.07,376.27c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM195.92,377.1c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M233.55,543.3c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM235.7,542.48c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M243.37,479.32c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM245.53,478.5c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M300.7,517.58c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM302.85,516.75c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M312.71,452.78c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM310.56,453.6c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M255.39,414.52c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM253.24,415.35c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M245.57,478.51c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM243.41,479.33c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M188.24,440.25c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM186.09,441.08c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M176.23,505.05c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM178.38,504.23c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M358.15,555.91c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM360.3,555.09c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M367.97,491.93c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM370.13,491.1c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M377.8,427.95c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM379.95,427.12c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69l2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M435.12,466.2c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM437.28,465.38c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M447.14,401.4c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM444.99,402.23c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M387.57,363.99c-1.05-27.65-1.81-55.63-2.23-83.26,0,0,2.2,1.76,2.2,1.76l68.7,54.86s.83.66.83.66c-23.33,8.73-46.53,17.28-69.5,25.97h0ZM389.85,363.12c22.13-8.6,44.03-17.3,65.81-25.85,0,0-.16,1.03-.16,1.03l-69.61-53.71s2.15-1.08,2.15-1.08c.93,26.67,1.49,52.95,1.8,79.6h0Z" />
      <path d="M385.56,283.68c6.82-16.21,14.03-32.77,21.47-48.69,0,0,.74-1.59.74-1.59l1.43.97,56.94,38.82s1.77,1.21,1.77,1.21c-24.56,2.73-58.17,6.44-82.35,9.28h0ZM387.83,283.4c22.52-2.84,55-7.31,77.77-10.34,0,0-.34,1.44-.34,1.44l-57.7-37.68s2.17-.62,2.17-.62c-6.99,16.05-14.26,31.44-21.89,47.2h0Z" />
      <path d="M454.79,338.26c2.9-22.04,6.13-44.48,9.74-66.4,0,0,1.66.6,1.66.6l78.34,28.54s1.94.71,1.94.71c0,0-1.91.76-1.91.76-22.7,8.98-67.13,26.72-89.78,35.79h0ZM456.91,337.4c25.24-10.38,61.56-25.72,87.06-36.38,0,0,.03,1.46.03,1.46l-78.8-27.25,1.94-1.16c-3.03,21.33-6.5,42.11-10.24,63.32h0Z" />
      <path d="M466.95,273.7c-3.06,22.22-6.42,44.69-10.06,66.8,0,0-1.92-1.53-1.92-1.53l-68.68-54.88s-1.29-1.03-1.29-1.03c27.51-3.15,54.84-6.15,81.95-9.35h0ZM464.47,274.01c-26.09,3.34-51.97,6.84-77.67,10.22,0,0,.34-1.22.34-1.22l69.62,53.69-2.32.92c3.05-21.37,6.4-42.34,10.02-63.6h0Z" />
      <path d="M502.31,440.54c2.88-22,6.06-44.43,9.67-66.31,0,0,1.57.41,1.57.41l84.26,21.73,2.26.58-2.12.94c-28.29,12.55-67.88,30.14-95.65,42.65h0ZM504.38,439.59c26.95-12.34,65.68-30.53,92.91-43.18,0,0,.14,1.53.14,1.53l-84.59-20.41s1.84-1.22,1.84-1.22c-3.04,21.33-6.54,42.07-10.29,63.29h0Z" />
      <path d="M512.16,376.47c10.16-25.73,20.85-51.87,31.83-77.23,0,0,1.28,1.76,1.28,1.76,0,0,35.61,48.95,35.61,48.95l.53.73-.83.31c-20.24,7.48-48.57,17.97-68.42,25.49h0ZM514.19,375.69c19.1-7.35,46.63-18.37,65.91-25.97,0,0-.3,1.03-.3,1.03l-36.53-48.27,2.15-.26c-10.04,24.89-20.39,48.94-31.23,73.47h0Z" />
      <path d="M596.13,397.45c-6.37-16.86-12.54-33.78-18.49-50.62,0,0,3.47,2.38,3.47,2.38l56.84,38.97s1.01.69,1.01.69c0,0-1.18.24-1.18.24l-40.15,8.05-1.5.3h0ZM599.1,396.82l38.46-8.72-.18.93-57.8-37.53s2.05-1.62,2.05-1.62c6.05,15.72,11.84,31.24,17.46,46.94h0Z" />
      <path d="M581.66,349.86c6.32,16.41,12.49,33,18.36,49.49,0,0-2.77-.72-2.77-.72l-84.23-21.88-2.12-.55s2.04-.76,2.04-.76l67.4-25.09,1.32-.49h0ZM579.05,350.86l-65.61,25.86-.08-1.31,84.63,20.26-1.8,2c-5.97-15.64-11.62-31.1-17.14-46.82h0Z" />
      <path d="M545.29,301.33c-9.87,25.03-20.21,50.57-30.86,75.27,0,0-.64,1.51-.64,1.51l-1.34-.91-57.01-38.72-1.22-.83c27.05-10.73,64.5-25.57,91.08-36.32h0ZM543.29,302.15c-25.28,10.36-61.6,25.73-87.13,36.4,0,0,.14-1.37.14-1.37l57.63,37.78-1.98.6c10.06-24.87,20.48-48.88,31.34-73.41h0Z" />
      <path d="M514.29,375.67c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM512.13,376.5c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M456.97,337.42c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM454.81,338.25c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M444.95,402.22c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM447.1,401.39c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M616.92,516.97c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM619.07,516.15c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M626.82,453.11c2.79-21.45,5.87-43.39,9.38-64.73,0,0,.25-1.57.25-1.57,0,0,1.52.35,1.52.35l79.21,18.33,2.19.51c-27.57,13.97-65.48,33.18-92.57,47.11h0ZM628.81,452.07c25.5-13.31,62.09-32.88,87.84-46.51,0,0,.2,1.52.2,1.52l-79.5-17.04s1.78-1.22,1.78-1.22c-3.02,21.33-6.54,42.03-10.31,63.24h0Z" />
      <path d="M684.12,491.23c10.45-29.22,21.41-58.85,32.67-87.73,0,0,1.27,2.16,1.27,2.16s34.78,59.12,34.78,59.12l.38.65-.69.26c-20.25,7.49-48.54,18.02-68.41,25.54h0ZM686.17,490.44c19.09-7.34,46.64-18.34,65.92-25.92,0,0-.31.9-.31.9l-35.8-58.51s2.18-.2,2.18-.2c-10.29,28.28-20.91,55.77-31.99,83.72h0Z" />
      <path d="M717.99,405.79c-10.29,28.95-21.06,58.43-32.2,87.05,0,0-1.35-.92-1.35-.92l-57.03-38.69-1.09-.74c27.21-13.79,64.97-32.93,91.67-46.7h0ZM716.09,406.78c-25.52,13.28-62.14,32.87-87.89,46.49,0,0,.07-1.33.07-1.33,0,0,57.62,37.81,57.62,37.81,0,0-1.94.62-1.94.62,10.3-28.27,21.01-55.65,32.15-83.6h0Z" />
      <path d="M741.39,529.5c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM743.54,528.67c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M753.4,464.7c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM751.25,465.52c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M686.26,490.43c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM684.1,491.25c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M674.24,555.23c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM676.39,554.4c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M638.99,388.44c-3.11,22.64-6.63,45.54-10.41,68.03,0,0-1.7-3.21-1.7-3.21l-29.56-55.79s-.31-.58-.31-.58c0,0,.63-.13.63-.13,13.94-2.81,27.7-5.49,41.34-8.32h0ZM636.62,388.95c-13.07,2.88-25.98,5.87-38.77,8.76l.32-.71,30.87,55.07s-2.3.4-2.3.4c2.98-21.38,6.25-42.3,9.87-63.53h0Z" />
      <path d="M629.06,452.26c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM626.91,453.08c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M598.74,396.79c-11.81,27.33-24.11,55.2-36.73,82.16,0,0-.66,1.42-.66,1.42,0,0-1.27-.86-1.27-.86l-57.03-38.69-1.18-.8c28.75-12.76,68.64-30.45,96.87-43.21h0ZM596.8,397.68c-26.99,12.32-65.73,30.53-92.98,43.19,0,0,.11-1.38.11-1.38l57.61,37.81-1.93.55c12-27.17,24.36-53.39,37.18-80.18h0Z" />
      <path d="M559.79,478.79c12.17-28.08,24.89-56.57,37.89-84.25,0,0,1.13,2.12,1.13,2.12l29.72,55.7.35.65s-.68.25-.68.25c-20.24,7.48-48.55,18-68.4,25.52h0ZM561.81,478.01c19.1-7.34,46.64-18.36,65.93-25.94,0,0-.33.91-.33.91l-30.71-55.16s2.15-.08,2.15-.08c-11.98,27.18-24.25,53.49-37.04,80.27h0Z" />
      <path d="M504.59,439.74c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM502.43,440.56c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M437.44,465.47c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM435.28,466.29c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M425.42,530.27c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM427.57,529.44c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M492.57,504.54c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM494.72,503.71c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M549.89,542.79c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM552.05,541.96c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M561.91,477.99c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM559.76,478.81c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M-63.02,415.94c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-60.86,415.12c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69,0,0,2.11-.93,2.11-.93-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M-43.36,287.98c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-41.21,287.16c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M4.13,390.21c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM6.29,389.39c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M13.96,326.23c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM16.11,325.41c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M71.28,364.49c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM73.44,363.66c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69,0,0,2.11-.93,2.11-.93-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M83.3,299.69c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM81.14,300.51c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M25.98,261.44c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM23.82,262.26c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M16.15,325.42c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM14,326.24c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M-53.19,351.96c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-51.04,351.14c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M-23.71,160.02c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-21.56,159.2c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24L-13.55,96.77s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M-4.06,32.06C-1.11,9.9,2.19-12.64,5.86-34.67c0,0,1.77,1.2,1.77,1.2L64.58,5.34l1.13.77C47.68,12.76,13.92,25.33-4.06,32.06h0ZM-1.9,31.23C17.17,23.86,44.68,12.86,63.94,5.27c0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69,0,0,2.11-.93,2.11-.93C5.22-10.78,1.81,10.02-1.9,31.23h0Z" />
      <path d="M7.96-32.74C5.01-10.58,1.71,11.96-1.96,33.99c0,0-1.77-1.2-1.77-1.2L-60.67-6.02s-1.13-.77-1.13-.77C-43.78-13.44-10.02-26.01,7.96-32.74h0ZM5.81-31.91C-13.27-24.54-40.77-13.54-60.03-5.95c0,0,.14-1.24.14-1.24L-2.2,30.51s-2.11.93-2.11.93C-1.31,10.1,2.1-10.7,5.81-31.91h0Z" />
      <path d="M43.44,134.29c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM45.59,133.47c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M53.27,70.31c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM55.42,69.49c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24L63.43,7.06l2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M110.59,108.56c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM112.74,107.74c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M122.61,43.76c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM120.45,44.59c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M65.28,5.51c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2L-3.35,32.23l-1.13-.77C13.54,24.82,47.31,12.24,65.28,5.51h0ZM63.13,6.34C44.06,13.71,16.55,24.71-2.71,32.3c0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69,0,0-2.11.93-2.11.93,3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M55.46,69.49c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2L-13.18,96.21l-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM53.3,70.32c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M-13.88,96.04c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-11.73,95.22c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24L-3.72,32.79s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M138.43,338.76c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM140.58,337.93c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M148.26,274.78c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM150.41,273.95c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69l2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M205.58,313.03c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM207.73,312.2c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M217.6,248.23c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM215.44,249.05c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M158.08,210.79c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM160.24,209.97c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M167.91,146.81c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2,0,0,56.95,38.81,56.95,38.81l1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM170.06,145.99c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M225.23,185.07c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM227.39,184.24c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M237.25,120.27c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM235.1,121.09c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M272.73,287.3c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM274.88,286.47c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M282.63,223.43c2.8-21.45,5.9-43.39,9.43-64.72,0,0,.27-1.67.27-1.67,0,0,1.59.52,1.59.52l70.51,23.26,1.82.6c-24.9,12.45-59.16,29.58-83.62,42h0ZM284.63,222.4c22.99-11.87,56-29.36,79.2-41.52,0,0,.12,1.45.12,1.45,0,0-70.91-22.03-70.91-22.03,0,0,1.86-1.15,1.86-1.15-3.01,21.33-6.51,42.04-10.27,63.25h0Z" />
      <path d="M339.92,261.56c7.51-27.5,15.47-55.4,23.76-82.64,0,0,1.49,1.86,1.49,1.86l43.45,54.25s.56.7.56.7l-.83.31c-20.26,7.49-48.55,18.02-68.43,25.53h0ZM342,260.76c19.08-7.35,46.62-18.35,65.9-25.93,0,0-.27,1-.27,1l-44.41-53.46s2.19-.44,2.19-.44c-7.43,26.6-15.21,52.49-23.4,78.83h0Z" />
      <path d="M365.17,181.07c-7.4,27.32-15.23,55.12-23.46,82.18,0,0-1.47-1-1.47-1l-57.01-38.72-1.06-.72c24.65-12.33,58.8-29.42,83-41.74h0ZM363.24,182.07c-23,11.84-56.04,29.35-79.25,41.5,0,0,.07-1.29.07-1.29l57.64,37.78s-1.99.73-1.99.73c7.43-26.59,15.28-52.39,23.53-78.72h0Z" />
      <path d="M294.57,158.52c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM292.42,159.34c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69,0,0-2.11.93-2.11.93,3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M284.75,222.5c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM282.59,223.32c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M227.42,184.25c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM225.27,185.07c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24,0,0,57.69,37.69,57.69,37.69l-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M215.41,249.05c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM217.56,248.22c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M180.05,82.1c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81s-1.13-.77-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM177.9,82.92c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M170.23,146.08c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM168.07,146.9c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M112.91,107.83c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM110.75,108.65c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M100.89,172.63c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM103.04,171.8c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M160.4,210.06c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM158.25,210.89c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M150.57,274.04c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM148.42,274.87c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M93.25,235.79c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM91.1,236.61c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M81.23,300.59c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM83.39,299.76c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M45.76,133.56c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM43.6,134.38c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M35.93,197.54c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM33.78,198.36c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
      <path d="M-33.41,224.09c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM-31.26,223.26c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M23.91,262.34c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81s1.13.77,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM26.07,261.51c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24,0,0-57.69-37.69-57.69-37.69l2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M33.74,198.36c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM35.89,197.53c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M91.06,236.61c2.95-22.16,6.25-44.7,9.92-66.73,0,0,1.77,1.2,1.77,1.2l56.95,38.81,1.13.77c-18.02,6.65-51.78,19.22-69.76,25.95h0ZM93.21,235.78c19.07-7.37,46.58-18.37,65.84-25.97,0,0-.14,1.24-.14,1.24l-57.69-37.69s2.11-.93,2.11-.93c-3,21.35-6.41,42.14-10.12,63.36h0Z" />
      <path d="M103.08,171.81c-2.95,22.16-6.25,44.7-9.92,66.73,0,0-1.77-1.2-1.77-1.2l-56.95-38.81-1.13-.77c18.02-6.65,51.78-19.22,69.76-25.95h0ZM100.92,172.63c-19.07,7.37-46.58,18.37-65.84,25.97,0,0,.14-1.24.14-1.24l57.69,37.69s-2.11.93-2.11.93c3-21.35,6.41-42.14,10.12-63.36h0Z" />
    </g>
  </svg>
);
