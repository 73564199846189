import { CampaignGivingForm } from './campaignTypes';

export interface IOrganizationFeatures {
  abTesting: boolean;
  advancedDesignations: boolean;
  applePay: boolean;
  chapters: boolean;
  corporateMatching: boolean;
  customCss: boolean;
  donorSelfServeBranding: boolean;
  googlePay: boolean;
  hostedPages: boolean;
  reporting: boolean;
  spreedlyPaypal: boolean; // This is what we currently use to know if an org can use PayPal, not the "paypal" org flag
  tributes: boolean;
  overridePaymentGateway: boolean;
  eventManagement: boolean;
  eventManagement2019: boolean;
  p2p2019: boolean;
  smsGiving: boolean;
  thankYouGift: boolean;
  legacyCustomFieldMapping: boolean;
  scaffolding: boolean;
}

export interface IPaymentGateway {
  id: string;
  name: string;
  backendName: string;
  gatewayType: string;
  isDefault?: boolean;
}

export interface IOrganizationInfo {
  donorSSP?: string;
  logoUrl?: string;
  features?: IOrganizationFeatures;
  startDate?: string;
  acceptedPaymentOptions?: string[];
  organizationSlug?: string;
  advancedDesignations?: boolean;
}

class ChartData {
  x: string;

  y: number;
}

export interface IOrganizationDashboard {
  chartData: {
    current: ChartData[];
    previous?: ChartData[];
  };
  firstTimeDonors: number;
  firstTimeDonorsChartData: ChartData[];
  recurringDonors: number;
  recurringDonorsChartData: ChartData[];
  totalDonations: number;
  totalDonationsDeltaPercentage: number;
  totalDonors: number;
  totalDonorsChartData: ChartData[];
  totalRevenue: number;
  totalRevenueDeltaPercentage: number;
}

export interface ChannelRevenue {
  totalAmount: number;
  revenuePercentage: number;
}

export enum ChannelType {
  GivingForms = 'givingForms',
  TextToGive = 'sms',
  PeerToPeer = 'p2p',
  Events = 'events',
  Other = 'other'
}

export type OrgRevenueByChannel = Record<ChannelType, ChannelRevenue>;

export enum PlotBy {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

export interface OrgCampaignsWithGivingForms {
  name: string;
  campaignId: string;
  campaignIsActive: boolean;
  campaignEndDate: string | undefined;
}

export interface OrgGivingForms {
  orgCampaignsWithGivingForms: OrgCampaignsWithGivingForms[];
  orgGivingForms: CampaignGivingForm[];
}

export interface ReferenceCodes {
  referenceCodes: string[];
}
