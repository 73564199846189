import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Blocker } from 'components';
import { BreadcrumbsProps } from 'components/Breadcrumbs';
import { ButtonProps } from 'components/Button';
import GivingFormEditor from 'components/GivingFormEditor';
import { MenuItemProps } from 'components/Menu';
import { EditableTextProps } from 'components/Text';
import { validateWYSIWYGHTML } from 'components/WYSIWYG/WYSIWYGUtils';
import { SaveAsTemplateDialog } from 'components/gms';
import { CopyEmbedDialog } from 'components/gms/CopyEmbedDialog';
import { CreateGivingFormWithHostedPageDialog } from 'components/gms/CreateGivingFormWithHostedPageDialog';
import { DeleteModalConfig } from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { DeleteTemplateModal } from 'components/gms/DeleteTemplateModal/DeleteTemplateModal';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { HostedPageSelectLayoutModal } from 'components/gms/HostedPageCreateModals';
import { HostedPageEditor } from 'components/gms/HostedPageEditor';
import { EnableHostedPageJumbotron } from 'components/gms/HostedPageEditor/EnableHostedPageJumbotron';
import NewGivingFormDialog from 'components/gms/NewGivingFormDialog';
import { PublishFirstTimeDialog } from 'components/gms/PublishFirstTimeDialog';
import { PublishWarningDialog } from 'components/gms/PublishWarningDialog';
import RedirectURLFormDialog from 'components/gms/RedirectURLFormDialog';
import { ReferenceCodeDialog } from 'components/gms/ReferenceCodeDialog';
import { SelectReceiptDialog } from 'components/gms/SelectReceiptDialog';
import { SmsFormSelectModal } from 'components/gms/SmsFormSelectModal';
import { TemplatePublishWarningDialog } from 'components/gms/TemplatePublishWarningDialog';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  ConfigContextProvider,
  useConfigContext
} from 'hooks/useConfigContext';
import { IframeContextProvider } from 'hooks/useEditorIframeContext';
import { OrganizationRole, getRoleInt } from 'hooks/useRenderIfRole';
import { useGetAllAbTests } from 'queries/UseAbTests';
import { useCampaignById } from 'queries/UseCampaigns';
import {
  useDeleteGivingFormTemplate,
  useGetHostedPageSlugs,
  useGivingFormByInstanceId,
  useUpdateGivingForm,
  useUpdateGivingFormTemplate,
  useValidateGivingFormName
} from 'queries/UseGivingForms';
import { useUser } from 'queries/UseUsers';
import { GivingFormModes } from 'services';
import editsRepositoryApi from 'services/editsRepositoryService';
import { HostedPageLayout, IGivingFormConfig } from 'types';
import { validatePageBreaks } from 'utils';
import { Receipts } from 'views/Emails/EditEmail/Receipts';

export const EditGivingForm = (): JSX.Element => {
  // Exported for testing with custom context
  const { givingFormId, givingFormTemplateId, campaignId } = useParams();
  const formId = givingFormId || givingFormTemplateId;
  const {
    data: { id: userId }
  } = useUser();
  const [addAlert] = useAlerts();
  const {
    isLoading: isLoadingGivingForm,
    data: backendGivingFormReference,
    refetch: getGivingForm
  } = useGivingFormByInstanceId(formId);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    location?.state?.initialTab || 'GivingForm'
  );
  const [initialGivingFormLoaded, setInitialGivingFormLoaded] = useState(false);
  const [checkNameGuard, setCheckNameGuard] = useState(false);
  const [isUnpublished, setIsUnpublished] = useState(true);
  const [deleteModalConfig, setDeleteModalConfig] = useState<DeleteModalConfig>(
    {
      open: false
    }
  );
  const {
    configData,
    updateName,
    handleSetForm,
    discardEdits,
    copyConfigToNewDraft,
    receiptId,
    isPublished,
    updateConfig
  } = useConfigContext<IGivingFormConfig>();

  const {
    envConfig,
    selectedOrganization,
    selectedCampaignName,
    setSelectedCampaignName,
    setSelectedCampaignId,
    organizationInfo
  } = useAppContext();

  const organizationId = selectedOrganization?.id;
  const givingFormIframeSrc = `${envConfig?.appsBaseUrl}/giving-form/edit-${formId}`;
  const hostedPageIframeSrc = `${envConfig?.appsBaseUrl}/hosted-page/edit-${formId}`;

  const {
    isLoading: isLoadingCampaignData,
    data: campaignData,
    isError: campaignDataError
  } = useCampaignById(organizationId, campaignId, { enabled: !!campaignId });

  const { data: abTestData, isLoading: isLoadingAbTests } = useGetAllAbTests(
    { organizationId: selectedOrganization.id },
    {
      enabled: !!givingFormId,
      onError: () => {
        addAlert({
          title: 'Uh oh. Looks like there was an error loading your A/B tests.',
          severity: 'error'
        });
      }
    }
  );

  useEffect(() => {
    // redirect if the giving form is part of an in-progress A/B test
    if (abTestData && givingFormId && campaignId) {
      const abTestGivingFormIds =
        abTestData
          ?.filter((abTest) => !abTest.endedAt)
          ?.map((abTest) => abTest.givingForm.id) || [];
      if (abTestGivingFormIds.includes(formId)) {
        navigate(`/campaigns/${campaignId}`, {
          state: { redirectedFromPublishedAbTest: true }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestData, givingFormId]);

  useEffect(() => {
    const checkForAbTestDraft = async () => {
      try {
        const getAllEditsResponse = await editsRepositoryApi.getAllEdits(
          'abTest',
          userId
        );
        const abTestEditGivingFormIds = getAllEditsResponse.map(
          (response) => response.id
        );
        if (campaignId && abTestEditGivingFormIds.includes(formId)) {
          navigate(`/campaigns/${campaignId}`, {
            state: { redirectedFromAbTestDraft: true }
          });
        }
      } catch (e) {
        addAlert({
          title: 'There was an error loading your A/B test drafts.',
          severity: 'error'
        });
      }
    };
    checkForAbTestDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaignData && !isLoadingCampaignData && !campaignDataError) {
      setSelectedCampaignName(campaignData.title);
      setSelectedCampaignId(campaignData.id);
    }
  }, [
    campaignData,
    campaignDataError,
    isLoadingCampaignData,
    setSelectedCampaignName,
    setSelectedCampaignId
  ]);

  const { mutateAsync: checkGivingFormName } = useValidateGivingFormName({
    campaignId,
    givingFormId: formId,
    name: configData.name,
    organizationId
  });

  const [showPublishWarningDialog, setShowPublishWarningDialog] =
    useState(false);
  const [showPublishFirstTimeDialog, setShowPublishFirstTimeDialog] =
    useState(false);
  const [showNewGivingFormDialog, setShowNewGivingFormDialog] = useState(false);
  const [showSaveAsTemplateDialog, setShowSaveAsTemplateDialog] =
    useState(false);
  const [showCopyEmbedDialog, setShowCopyEmbedDialog] = useState(false);
  const [showSelectReceiptDialog, setShowSelectReceiptDialog] =
    useState<boolean>(false);
  const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] =
    useState(false);
  const [nameIsDuplicate, setNameIsDuplicate] = useState(false);
  const [
    showTemplatePublishWarningDialog,
    setShowTemplatePublishWarningDialog
  ] = useState(false);
  const [showReferenceCodeDialog, setShowReferenceCodeDialog] = useState(false);
  const [showRedirectURLDialog, setShowRedirectURLDialog] = useState(false);
  const [showCopyPageLinkButton, setShowCopyPageLinkButton] = useState(false);
  const [showUpdateHostedPageLayout, setShowUpdateHostedPageLayout] =
    useState(false);
  const [selectedLayout, setSelectedLayout] = useState<HostedPageLayout>(
    configData?.config?.hostedPageConfig?.layout
  );
  const [pageTitle, setPageTitle] = useState<string>(
    configData?.config?.hostedPageConfig?.pageTitle || configData?.name
  );
  const [pageFavicon, setPageFavicon] = useState<string>(
    configData?.config?.hostedPageConfig?.pageFavicon
  );
  const [showSmsFormSelectDialog, setShowSmsFormSelectDialog] =
    useState<boolean>(false);
  const [showNewfGFWithHPModal, setShowNewGFWithHPModal] = useState(false);

  const { data: slugs } = useGetHostedPageSlugs({
    organizationId,
    givingFormId: formId,
    queryOptions: {
      enabled: showCopyPageLinkButton
    }
  });

  const { mutate: deleteTemplate } = useDeleteGivingFormTemplate({
    givingFormTemplateId,
    organizationId: selectedOrganization.id
  });

  useEffect(() => {
    // when data is loaded, if we have a hosted page config
    // update selected layout with value
    setSelectedLayout(configData?.config?.hostedPageConfig?.layout);
    setPageTitle(
      configData?.config?.hostedPageConfig?.pageTitle || configData?.name
    );
    setPageFavicon(configData?.config?.hostedPageConfig?.pageFavicon);
  }, [configData?.name, configData?.config?.hostedPageConfig]);

  useEffect(() => {
    // once isPublised check & backendGivingFormReference is loaded, check to see if we have a hosted page
    if (
      isPublished &&
      backendGivingFormReference?.config?.hostedPageConfig &&
      givingFormId
    ) {
      setShowCopyPageLinkButton(true);
    }
  }, [isPublished, backendGivingFormReference, givingFormId]);

  // Display apply receipt changes dialog if the user just updated a receipt that is not currently selected
  useEffect(() => {
    if (
      !!location?.state?.emailId &&
      receiptId &&
      location?.state?.emailId !== receiptId
    ) {
      setShowSelectReceiptDialog(true);
    }
  }, [location?.state?.emailId, receiptId, activeTab]);

  // This useEffect is only for handling the initial load.  Any follow-up updates require checking values before overwriting data
  useEffect(() => {
    if (!isLoadingGivingForm && !initialGivingFormLoaded) {
      setInitialGivingFormLoaded(true);
      handleSetForm({
        ...backendGivingFormReference,
        seedConfigPublishedAt: backendGivingFormReference.updatedAt
      });
      if (
        backendGivingFormReference.createdAt.getTime() ===
        backendGivingFormReference.updatedAt.getTime()
      ) {
        setIsUnpublished(true);
      } else {
        setIsUnpublished(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGivingForm]);

  useEffect(() => {
    // First guard is to prevent an API call before the name is even available
    if (!initialGivingFormLoaded) {
      return;
    }
    // Second guard is to prevent an API call when the giving form loads.  Any changes after that will fire the check
    if (!checkNameGuard) {
      setCheckNameGuard(true);
      return;
    }

    const checkIfUnique = async () => {
      const validFormResp = await checkGivingFormName();
      if (!validFormResp) {
        setNameIsDuplicate(true);
      } else {
        setNameIsDuplicate(false);
      }
    };

    checkIfUnique();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.name]);

  const { mutateAsync: publishGivingForm, isLoading: isLoadingPublish } =
    useUpdateGivingForm({
      givingFormId,
      name: configData.name,
      config: configData.config,
      receiptId,
      campaignStartDate: campaignData?.startDate,
      organizationId,
      isSmsForm: configData.isSmsForm
    });

  const { mutateAsync: saveGivingFormTemplate, isLoading: isTemplateSaving } =
    useUpdateGivingFormTemplate({
      givingFormTemplateId,
      name: configData.name,
      config: configData.config,
      receiptId,
      organizationId
    });

  const handlePublishForm = async (firstPublish?: boolean) => {
    try {
      const data = givingFormId
        ? await publishGivingForm(false)
        : await saveGivingFormTemplate();
      discardEdits();

      if (firstPublish) {
        setShowPublishFirstTimeDialog(true);
      } else {
        addAlert({
          title: givingFormId
            ? 'Your changes were successfully published.'
            : 'Your changes were successfully saved.'
        });
      }
      handleSetForm({
        ...data,
        seedConfigPublishedAt: data.updatedAt
      });
      setIsUnpublished(false);
      setShowCopyPageLinkButton(
        !!givingFormId && !!data.config?.hostedPageConfig
      );
    } catch (error) {
      let message = 'Unable to process.'; // Fallback error message incase something unexpected threw a non-base Error
      if (error instanceof Error) {
        message = error.message;
      }
      addAlert({
        title: givingFormId ? 'Error Publishing Form' : 'Error Saving Template',
        description: message,
        severity: 'error'
      });
    }
  };

  const handlePublishButtonClick = async () => {
    const { data: currentBackendGivingForm } = await getGivingForm();

    const isValidWYSIWYGHTML = validateWYSIWYGHTML(configData);
    const { isValid: isValidPageBreaks, ...errorStates } = validatePageBreaks(
      configData.config
    );

    const includesReceipt = !!receiptId;

    if (!includesReceipt) {
      addAlert({
        severity: 'error',
        title: 'Receipt Required',
        description: 'You must select a receipt to publish the form.'
      });
    } else if (!isValidWYSIWYGHTML) {
      addAlert({
        severity: 'error',
        title: 'Invalid Tag',
        description:
          'Could not publish the form because your thank you body message contains an invalid tag.'
      });
    } else if (!isValidPageBreaks) {
      const description = [];

      if (errorStates.isFirst) {
        description.push('Start of Form');
      }
      if (errorStates.isLast) {
        description.push('End of Form');
      }
      if (errorStates.isNextToAnotherPageBreak) {
        description.push('Next to Other Page Breaks');
      }

      addAlert({
        severity: 'error',
        title: 'Invalid Page Breaks',
        description: `Could not publish the form because your page breaks are in the following invalid locations: ${description.join(
          ', '
        )}`
      });
    } else if (
      currentBackendGivingForm?.updatedAt > configData.seedConfigPublishedAt
    ) {
      setShowPublishWarningDialog(true);
    } else {
      const firstTimePublish =
        !!givingFormId &&
        currentBackendGivingForm.createdAt.getTime() ===
          currentBackendGivingForm.updatedAt.getTime();
      handlePublishForm(firstTimePublish);
    }
  };

  const handleCopyPageLinkClick = () => {
    window.navigator.clipboard.writeText(
      `${envConfig.hostedPageDomain}${slugs.organizationSlug}/${slugs.givingFormSlug}`
    );
    addAlert({
      title: 'Link copied to clipboard',
      severity: 'success'
    });
  };

  // Get all current Giving forms ids that have unpublished changes
  const getAllGivingFormEditsIds = async () => {
    const allGivingFormEditsIds = await editsRepositoryApi.getAllEdits(
      'givingForm'
    );
    return allGivingFormEditsIds;
  };

  const handleSaveAsTemplateClick = async () => {
    const allGivingFormEditsIds = await getAllGivingFormEditsIds();
    if (allGivingFormEditsIds.find((edits) => edits.id === formId)) {
      // Warn the user of unpublished edits
      setShowTemplatePublishWarningDialog(true);
    } else {
      setShowSaveAsTemplateDialog(true);
    }
  };

  const handleTemplateDelete = () => {
    deleteTemplate(undefined, {
      onSuccess: () => {
        addAlert({
          title: `The giving form template "${configData.name}" was successfully deleted.`,
          severity: 'success'
        });
        navigate('/giving-form-templates');
      },
      onError: () => {
        addAlert({
          title:
            'Uh oh. Looks like there was an error deleting the giving form template.',
          severity: 'error'
        });
      }
    });
  };

  const toggleHostedPageLayoutModal = () => {
    setShowUpdateHostedPageLayout(true);
  };

  const updateExistingHostedPageLayout = async () => {
    const newConfig = { ...(configData.config as IGivingFormConfig) };
    const newHostedPageConfig = newConfig.hostedPageConfig;

    newHostedPageConfig.pageTitle = pageTitle;
    newHostedPageConfig.pageFavicon = pageFavicon;

    if (selectedLayout === HostedPageLayout.FullWidth) {
      // we are going from a columned layout to a single column layout
      // take everything in sideBlocks and move it to topBlocks
      newHostedPageConfig.topBlocks = newHostedPageConfig.topBlocks.concat(
        newHostedPageConfig.sideBlocks
      );
      newHostedPageConfig.sideBlocks = [];
    }

    newHostedPageConfig.layout = selectedLayout;
    updateConfig(newConfig);
  };

  const titleProps: EditableTextProps = {
    value: configData.name,
    textFieldProps: {
      onBlur: async (e) => {
        const name = e.target.value;
        if (name === '') {
          return;
        }

        updateName(name);
      },
      error: nameIsDuplicate
    }
  };
  const breadcrumbsProps: BreadcrumbsProps = {
    breadcrumbs: givingFormId
      ? [
          {
            label: 'All Campaigns',
            path: '/campaigns'
          },
          {
            label: selectedCampaignName || 'Campaign Home',
            path: `/campaigns/${campaignId}`
          },
          {
            label: configData.name,
            path: null
          },
          {
            label: 'Giving Form Builder',
            path: `/campaigns/${campaignId}/giving-forms/${formId}/edit`
          }
        ]
      : [
          {
            label: 'All Templates',
            path: '/giving-form-templates'
          },
          {
            label: 'Template Builder',
            path: `/giving-form-templates/${formId}/edit`
          }
        ]
  };

  const primaryButtonProps: ButtonProps = givingFormId
    ? {
        children: 'Publish Form',
        onClick: handlePublishButtonClick,
        disabled: isLoadingPublish
      }
    : {
        children: 'Publish Template',
        onClick: () => handlePublishForm(),
        disabled: isTemplateSaving
      };

  const secondaryButtonProps: ButtonProps = {
    children: 'Copy Page Link',
    onClick: handleCopyPageLinkClick,
    disabled: isUnpublished,
    variant: 'secondary'
  };

  const subtitleProps = {
    campaignTitle: selectedCampaignName
  };

  const duplicateFormOnClick = () => {
    if (configData?.config?.hostedPageConfig) {
      setShowNewGFWithHPModal(true);
    } else {
      setShowNewGivingFormDialog(true);
    }
  };

  const featureMenuItems: MenuItemProps[] = givingFormId
    ? [
        {
          label: 'Get Embed Code',
          onClick: () => setShowCopyEmbedDialog(true),
          disabled: isUnpublished
        }
      ]
    : [];

  if (
    getRoleInt(selectedOrganization?.role) >=
    getRoleInt(OrganizationRole.Editor)
  ) {
    if (givingFormId) {
      featureMenuItems.push({
        label: 'Duplicate Form',
        onClick: () => duplicateFormOnClick()
      });
    }
    featureMenuItems.push({
      label: 'Save as Template',
      onClick: () => handleSaveAsTemplateClick()
    });
    if (givingFormTemplateId) {
      featureMenuItems.push({
        label: 'Delete Template',
        onClick: () => setShowDeleteTemplateDialog(true)
      });
    }
    if (givingFormId) {
      featureMenuItems.push({
        label: 'Reference Code',
        onClick: () => setShowReferenceCodeDialog(true)
      });
      featureMenuItems.push({
        label: 'Redirect URL',
        onClick: () => setShowRedirectURLDialog(true)
      });
    }

    if (configData?.config?.hostedPageConfig) {
      featureMenuItems.push(
        ...[
          {
            label: 'Update Hosted Page Details',
            onClick: () => toggleHostedPageLayoutModal()
          }
        ]
      );
    }

    featureMenuItems.push({
      label: 'Use as SMS Form',
      onClick: () => setShowSmsFormSelectDialog(true),
      disabled: !!configData.editsSavedTime || configData.isSmsForm
    });
  }
  const tabsProps = [
    {
      label: 'Form',
      value: 'GivingForm'
    },
    {
      label: 'Confirmation',
      value: 'ThankYou',
      disabled: !!configData?.config?.redirectURL
    },
    {
      label: 'Receipt',
      value: 'Receipt'
    }
  ];

  if (organizationInfo?.features?.hostedPages) {
    tabsProps.push({
      label: 'Hosted Page',
      value: 'HostedPage'
    });
  }

  const handleRedirectUrlChange = (url: string) => {
    if (url && activeTab === 'ThankYou') {
      setActiveTab('GivingForm');
    }
  };

  return (
    <>
      <Blocker
        block={
          isLoadingGivingForm ||
          isLoadingPublish ||
          isLoadingAbTests ||
          isTemplateSaving
        }
      >
        <FeatureHeader
          titleProps={titleProps}
          subtitleProps={subtitleProps}
          breadcrumbsProps={breadcrumbsProps}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={showCopyPageLinkButton && secondaryButtonProps}
          featureMenuItems={featureMenuItems}
          tabsProps={tabsProps}
          isLoaded={initialGivingFormLoaded}
          activeTab={activeTab}
          nameIsDuplicate={nameIsDuplicate}
          setActiveTab={setActiveTab}
        />
        {activeTab === 'Receipt' && <Receipts />}
        {(activeTab === 'GivingForm' || activeTab === 'ThankYou') && (
          <IframeContextProvider
            key="giving-form"
            iframeSrc={givingFormIframeSrc}
            setDeleteModalConfig={setDeleteModalConfig}
            mode={GivingFormModes.EDIT}
          >
            <GivingFormEditor
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              deleteModalConfig={deleteModalConfig}
              setDeleteModalConfig={setDeleteModalConfig}
            />
          </IframeContextProvider>
        )}
        {activeTab === 'HostedPage' && configData?.config?.hostedPageConfig && (
          <IframeContextProvider
            key="hosted-page"
            iframeSrc={hostedPageIframeSrc}
            setDeleteModalConfig={setDeleteModalConfig}
            mode={GivingFormModes.EDIT}
          >
            <HostedPageEditor
              deleteModalConfig={deleteModalConfig}
              setDeleteModalConfig={setDeleteModalConfig}
            />
          </IframeContextProvider>
        )}
        {activeTab === 'HostedPage' &&
          !configData?.config?.hostedPageConfig && (
            <EnableHostedPageJumbotron />
          )}
      </Blocker>

      <PublishWarningDialog
        open={showPublishWarningDialog}
        onConfirm={handlePublishForm}
        onClose={() => setShowPublishWarningDialog(false)}
        onDiscard={async () => {
          discardEdits();
          handleSetForm({
            ...backendGivingFormReference,
            seedConfigPublishedAt: backendGivingFormReference.updatedAt
          });
        }}
      />

      <PublishFirstTimeDialog
        open={showPublishFirstTimeDialog}
        onClose={() => setShowPublishFirstTimeDialog(false)}
        campaignName={campaignData?.title}
      />

      <NewGivingFormDialog
        open={showNewGivingFormDialog}
        onClose={() => setShowNewGivingFormDialog(false)}
        onCreate={async (createdGivingForm) => {
          const { updatedAt, embedInstanceId, name } = createdGivingForm;

          handleSetForm({
            ...createdGivingForm,
            seedConfigPublishedAt: updatedAt
          });
          await copyConfigToNewDraft(embedInstanceId, updatedAt, name);
        }}
        templateId={formId}
      />
      <RedirectURLFormDialog
        open={showRedirectURLDialog}
        onClose={() => setShowRedirectURLDialog(false)}
        onUpdate={handleRedirectUrlChange}
      />

      <SaveAsTemplateDialog
        isOpen={showSaveAsTemplateDialog}
        onClose={() => setShowSaveAsTemplateDialog(false)}
        givingFormId={formId}
      />

      <CopyEmbedDialog
        open={showCopyEmbedDialog}
        onClose={() => setShowCopyEmbedDialog(false)}
        givingFormId={formId}
      />

      <SelectReceiptDialog
        open={showSelectReceiptDialog}
        onClose={() => {
          location.state = null;
          window.history.replaceState({}, '');
          setShowSelectReceiptDialog(false);
        }}
        givingFormName={configData.name}
        receiptId={location?.state?.emailId}
      />

      <TemplatePublishWarningDialog
        open={showTemplatePublishWarningDialog}
        onConfirm={() => setShowSaveAsTemplateDialog(true)}
        onClose={() => setShowTemplatePublishWarningDialog(false)}
      />

      <ReferenceCodeDialog
        open={showReferenceCodeDialog}
        onClose={() => setShowReferenceCodeDialog(false)}
      />

      <HostedPageSelectLayoutModal
        updatingLayout
        open={showUpdateHostedPageLayout}
        onClose={() => {
          setShowUpdateHostedPageLayout(false);
          setSelectedLayout(configData?.config?.hostedPageConfig?.layout);
          setPageTitle(configData?.config?.hostedPageConfig?.pageTitle);
          setPageFavicon(configData?.config?.hostedPageConfig?.pageFavicon);
        }}
        onNext={() => {
          setShowUpdateHostedPageLayout(false);
          updateExistingHostedPageLayout();
        }}
        nextEnabled={
          selectedLayout !== configData?.config?.hostedPageConfig?.layout ||
          pageTitle !== configData?.config?.hostedPageConfig?.pageTitle ||
          pageFavicon !== configData?.config?.hostedPageConfig?.pageFavicon
        }
        layoutSelection={selectedLayout}
        setLayoutSelection={setSelectedLayout}
        pageMetadata={{
          pageFavicon,
          setPageFavicon,
          pageTitle,
          setPageTitle
        }}
      />

      <SmsFormSelectModal
        open={showSmsFormSelectDialog}
        onClose={() => setShowSmsFormSelectDialog(false)}
      />

      <DeleteTemplateModal
        open={showDeleteTemplateDialog}
        onClose={() => setShowDeleteTemplateDialog(false)}
        onConfirm={handleTemplateDelete}
      />

      <CreateGivingFormWithHostedPageDialog
        open={showNewfGFWithHPModal}
        onClose={() => setShowNewGFWithHPModal(false)}
        templateId={formId}
        isDuplicate
      />
    </>
  );
};

export default () => {
  const { givingFormId, givingFormTemplateId } = useParams();
  const formId = givingFormId || givingFormTemplateId;

  return (
    <ConfigContextProvider<IGivingFormConfig>
      id={formId}
      type="givingForm"
      key={formId}
    >
      <EditGivingForm />
    </ConfigContextProvider>
  );
};
