/* eslint-disable no-useless-constructor */
import { MouseEvent, useState } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Menu, { MenuItemProps } from 'components/Menu';
import Tabs, { Tab } from 'components/Tabs';
import Text from 'components/Text';
import { NavLinkWithChip } from 'components/gms/AppHeader';
import { OrgSelector } from 'components/gms/OrgSelector';
import { useAppContext } from 'hooks/useAppContext';
import { OrganizationRole, getRoleInt } from 'hooks/useRenderIfRole';
import { useUser } from 'queries/UseUsers';
import { logoutService } from 'services/auth0Service';
import { showPrismatic } from 'services/prismaticService';
import { IOrganization } from 'services/types/userTypes';
import './AppHeader.scss';


export const AppHeader = (): JSX.Element => {
  const { data: user } = useUser();
  const { pathname: currentPath } = useLocation();
  const { organizationInfo, selectedOrganization } = useAppContext();
  const dropdownLabel = `Hi, ${user?.firstName}`;

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);
  const settingsMenuOpen = Boolean(settingsMenuAnchorEl);
  const handleUserMenuClick = (event: MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };
  const handleSettingsMenuClick = (event: MouseEvent<HTMLElement>) => {
    setSettingsMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setUserMenuAnchorEl(null);
    setSettingsMenuAnchorEl(null);
  };
  const handleLogout = async () => {
    await logoutService();
  };

  const handleIntegrationsClick = async () => {
    try {
      // Close the settings menu
      handleClose();
      await showPrismatic(selectedOrganization.id);

    } catch (error) {
      console.error('Error showing integrations marketplace:', error);
    }
  };
  const navTabs = [
    {
      label: 'Dashboard',
      to: '/dashboard',
      value: '/dashboard'
    },
    {
      label: 'Campaigns',
      to: '/campaigns',
      value: '/campaigns'
    },
    {
      label: 'Reporting',
      to: organizationInfo?.features?.reporting ? '/reporting' : '/donations',
      value: '/reporting'
    },
    {
      label: 'A/B Testing',
      to: '/strategy',
      value: '/strategy',
      isFeatureEnabled: organizationInfo?.features?.abTesting
    }
  ];

  const reportingViews = ['/donations', '/donations/recurring', '/donors'];

  const isReportingTab = (path: string) =>
    reportingViews.includes(path) ? '/reporting' : false;

  const profileMenu: MenuItemProps[] = [
    { label: 'My Profile', path: 'my-profile' },
    { label: 'Logout', path: '/', onClick: handleLogout }
  ];

  const settingsMenu: MenuItemProps[] = [
    {
      label: 'Organization Settings',
      path: 'settings/general',
      onClick: handleClose
    },
    ...(getRoleInt(selectedOrganization.role) >=
    getRoleInt(OrganizationRole.Editor)
      ? [{ label: 'Users', path: 'users', onClick: handleClose }]
      : []),
    {
      label: 'Payment Gateways',
      path: 'settings/payment-gateways',
      onClick: handleClose
    },
    {
      label: 'Reconciliation',
      path: 'settings/reconciliation',
      onClick: handleClose
    },
    {
      label: 'Integrations',
      path: 'settings/integrations',
      onClick: handleClose
    },
    {
      label: 'Integrations (Beta)',
      onClick: handleIntegrationsClick
    },
    { label: 'Webhooks', path: 'settings/webhooks', onClick: handleClose },
    { label: 'Text Settings', path: 'settings/texting', onClick: handleClose },
    {
      label: 'Designated Funds',
      path: 'settings/designated-funds',
      onClick: handleClose
    },
    {
      label: 'New Offline Donation',
      path: 'donations/offline/new'
    },
    {
      label: 'Import Donations',
      path: 'donations/offline/import'
    },
    {
      label: 'Manage Templates',
      path: 'giving-form-templates',
      onClick: handleClose
    },
    {
      label: 'Manage Receipts',
      path: 'receipts',
      onClick: handleClose
    }
  ];

  const menuIconClass = clsx('AppHeader-MenuIcon', {
    'AppHeader-MenuIconOpen': userMenuOpen
  });
  const settingsButtonClass = clsx('settings-menu', {
    'settings-menu-selected': settingsMenuOpen
  });

  return (
    <Box className="AppHeader-Container">
      <Stack
        className="fluid-container"
        direction="row"
        justifyContent="space-between"
        height="100%"
        alignItems="center"
      >
        <Box className="AppHeader-LogoContainer">
          <Link to="/">
            <Icon
              className="AppHeader-Logo"
              icon={ICONS.IDONATE_LOGO}
              viewBox="0 0 85 24"
            />
          </Link>
        </Box>
        <Tabs
          classes={{
            root: 'AppHeader-Tabs',
            scroller: 'AppHeader-TabsScroller'
          }}
          value={
            // set to false if it's not any of the four top tabs
            // see more: https://mui.com/api/tabs/#props - go to the `value` prop
            navTabs.map((tab) => tab.value).includes(currentPath)
              ? currentPath
              : isReportingTab(currentPath)
          }
        >
          {navTabs.map(({ isFeatureEnabled, ...tab }) => (
            <Tab
              className="AppHeader-NavTab"
              key={tab.label}
              component={NavLinkWithChip(isFeatureEnabled)}
              {...tab}
            />
          ))}
        </Tabs>
        <div className="AppHeader-Menu">
          <IconButton
            className={settingsButtonClass}
            icon={ICONS.SETTINGS}
            label="settings"
            onClick={handleSettingsMenuClick}
          />
          {user && (
            <OrgSelector orgs={user?.organizations as IOrganization[]} />
          )}
          <Stack
            className="AppHeader-UserMenu"
            direction="row"
            onClick={handleUserMenuClick}
          >
            <Text className="AppHeader-DropdownText" variant="h8">
              {dropdownLabel}
            </Text>
            <Icon className={menuIconClass} icon={ICONS.TRIANGLE} />
          </Stack>
        </div>
        <Menu
          menuItems={profileMenu}
          anchorEl={userMenuAnchorEl}
          open={userMenuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Menu
          menuItems={settingsMenu}
          anchorEl={settingsMenuAnchorEl}
          open={settingsMenuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Stack>
    </Box>
  );
};
