import { FunctionComponent } from 'react';
import {
  Box,
  ButtonUnstyled,
  ButtonUnstyledProps,
  Icon as MuiIcon,
  Stack
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useOrgFeatures } from 'hooks/useOrgFeatures';
import {
  BlockTypes,
  ElementLibraryGivingFormViews,
  GivingFormBlockType,
  IGivingFormConfig
} from 'types';
import './RootMenu.scss';

const RootViewMenuItem = ({
  children,
  icon,
  ...props
}: ButtonUnstyledProps & { icon: FunctionComponent }) => (
  <Box className="ElementLibrary-RootMenu--item">
    <ButtonUnstyled {...props}>
      <Stack
        className="ElementLibrary-RootMenu--item-container"
        direction="row"
      >
        <MuiIcon component={icon} />
        <Text variant="h4">{children}</Text>
        <Icon icon={ICONS.CHEVRON_RIGHT} />
      </Stack>
    </ButtonUnstyled>
  </Box>
);

const RootViewContent = ({
  setView
}: {
  setView: (view: ElementLibraryGivingFormViews) => void;
}): JSX.Element => {
  const { givingFormTemplateId } = useParams();
  const {
    configData: {
      config: { blocks }
    },
    setHighlightedBlock
  } = useConfigContext<IGivingFormConfig>();
  const features = useOrgFeatures();

  const { organizationInfo } = useAppContext();
  const hasCorporateMatching = organizationInfo?.features?.corporateMatching;
  const hasTributes = organizationInfo?.features?.tributes;

  const getBlockId = (blockType: BlockTypes) =>
    blocks.find((block: GivingFormBlockType) => block.blockType === blockType)
      ?.id || '';

  const handleSetView = (view: ElementLibraryGivingFormViews) => {
    // We need to set the view
    // but also scroll to the top of the container
    // in case we're independently scrolled down
    setView(view);
    const viewWindow = document.getElementsByClassName(
      'active-menu-container'
    )[0];
    if (viewWindow) {
      viewWindow.scrollTop = 0;
    }
  };

  return (
    <Stack id="ElementLibrary-RootMenu">
      <Box>
        <Text variant="h3">Commonly Used</Text>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('header');
            handleSetView(ElementLibraryGivingFormViews.HeaderView);
          }}
          icon={ICONS.LAYOUT_NAVBAR}
        >
          Header
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock(getBlockId(BlockTypes.GiftOptionBlock));
            handleSetView(ElementLibraryGivingFormViews.GiftOptionsView);
          }}
          // eslint-disable-next-line react/no-unstable-nested-components
          icon={() => <Icon icon={ICONS.COIN} />}
        >
          Donation Options
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock(getBlockId(BlockTypes.RecurringOptions));
            handleSetView(ElementLibraryGivingFormViews.RecurringOptionsView);
          }}
          icon={ICONS.REPEAT}
        >
          Recurring Options
        </RootViewMenuItem>
        {!givingFormTemplateId && (
          <RootViewMenuItem
            onClick={() => {
              setHighlightedBlock(getBlockId(BlockTypes.DesignationsBlock));
              handleSetView(
                ElementLibraryGivingFormViews.DesignationsBlockView
              );
            }}
            icon={ICONS.HOUSE}
          >
            Designations
          </RootViewMenuItem>
        )}
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock(getBlockId(BlockTypes.PaymentSection));
            handleSetView(ElementLibraryGivingFormViews.PaymentSectionView);
          }}
          icon={ICONS.CREDIT_CARD}
        >
          Payment Info
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() =>
            handleSetView(ElementLibraryGivingFormViews.CustomFieldView)
          }
          icon={ICONS.FILE_CODE}
        >
          Custom Field
        </RootViewMenuItem>
      </Box>
      <Box>
        <Text variant="h3">More Options</Text>
        {hasCorporateMatching && (
          <RootViewMenuItem
            onClick={() => {
              setHighlightedBlock(
                getBlockId(BlockTypes.CorporateMatchingBlock)
              );
              handleSetView(
                ElementLibraryGivingFormViews.CorporateMatchingView
              );
            }}
            icon={ICONS.SKYSCRAPER}
          >
            Corporate Matching
          </RootViewMenuItem>
        )}
        <RootViewMenuItem
          onClick={() => {
            handleSetView(ElementLibraryGivingFormViews.OptInOptionsView);
          }}
          icon={ICONS.MAIL}
        >
          Opt In Options
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            handleSetView(ElementLibraryGivingFormViews.ImpactView);
          }}
          icon={ICONS.ICE_CREAM_2}
        >
          Impact
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('recurringGiftPrompt');
            handleSetView(
              ElementLibraryGivingFormViews.RecurringGiftPromptView
            );
          }}
          icon={ICONS.CALENDAR_STATS}
        >
          Recurring Gift Prompt
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            handleSetView(ElementLibraryGivingFormViews.CustomContentBlockView);
          }}
          icon={ICONS.MESSAGE2}
        >
          Custom Content
        </RootViewMenuItem>
        {hasTributes && (
          <RootViewMenuItem
            onClick={() => {
              handleSetView(ElementLibraryGivingFormViews.TributeView);
            }}
            icon={ICONS.CERTIFICATE}
          >
            Tribute
          </RootViewMenuItem>
        )}
        {features?.thankYouGift && (
          <RootViewMenuItem
            onClick={() => {
              handleSetView(ElementLibraryGivingFormViews.ThankYouGiftView);
            }}
            icon={ICONS.GIFT}
          >
            Thank You Gift
          </RootViewMenuItem>
        )}
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('footer');
            handleSetView(ElementLibraryGivingFormViews.FooterView);
          }}
          icon={ICONS.LAYOUT_BOTTOMBAR}
        >
          Footer
        </RootViewMenuItem>
      </Box>
    </Stack>
  );
};

export default RootViewContent;
