import { ReactNode, useState } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { DEFAULT_ORGANIZATION } from 'constants/organizationConstants';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useDeleteGivingFormTemplate,
  useDeleteTemplateShare
} from 'queries/UseGivingForms';
import { DeleteTemplateModal } from '../DeleteTemplateModal/DeleteTemplateModal';
import { ShareTemplateModal } from '../ShareTemplateModal/ShareTemplateModal';
import { UnsubscribeFromShareDialog } from '../UnsubscribeFromShareDialog/UnsubscribeFromShareDialog';
import './TemplatePreviewCard.scss';

type TemplatePreviewCardProps = {
  templateOrgId: string;
  templateName: string;
  templateId: string;
  templateDescription?: string;
  onTemplateClick?: () => void;
  isGlobalTemplate?: boolean;
  showHoverButtons?: boolean;
  hasUnpublishedChanges?: boolean;
  preview?: ReactNode;
};

/**
 * A card used to display a template option
 */
const TemplatePreviewCard = ({
  templateOrgId,
  templateName,
  templateId,
  templateDescription,
  onTemplateClick,
  isGlobalTemplate = false,
  showHoverButtons = false,
  hasUnpublishedChanges = false,
  preview = null
}: TemplatePreviewCardProps) => {
  const { envConfig, selectedOrganization } = useAppContext();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false);

  const { mutateAsync: unsubscribeFromShareMutation } =
    useDeleteTemplateShare();

  const { mutate: deleteTemplate } = useDeleteGivingFormTemplate({
    givingFormTemplateId: templateId,
    organizationId: selectedOrganization.id
  });
  const [addAlert] = useAlerts();

  // Select consistent random color for each card.
  // This will likely be replaced once there is a
  // feature that can be represented by the card color
  // (ex: Teplate Tags)
  const backgroundColorKey = templateId.charCodeAt(0) % 3;

  const headerClasses = clsx(
    'template-preview-header',
    backgroundColorKey === 0 && 'purple-gradient',
    backgroundColorKey === 1 && 'green-gradient',
    backgroundColorKey === 2 && 'yellow-gradient'
  );

  const handleEdit = () => {
    navigate(`/giving-form-templates/${templateId}/edit`);
  };

  const handleDelete = () => {
    deleteTemplate(undefined, {
      onSuccess: () => {
        addAlert({
          title: `The giving form template "${templateName}" was successfully deleted.`,
          severity: 'success'
        });
      },
      onError: () => {
        addAlert({
          title:
            'Uh oh. Looks like there was an error deleting the giving form template.',
          severity: 'error'
        });
      }
    });
  };

  const handleUnsubscribe = () => {
    unsubscribeFromShareMutation({
      organizationId: templateOrgId,
      sharedOrganizationId: selectedOrganization.id,
      templateId
    });
  };

  const isSharedTemplate = () =>
    templateOrgId !== selectedOrganization.id &&
    templateOrgId !== DEFAULT_ORGANIZATION;

  const hoverButtons = () => (
    <div className="overview-hover">
      {!isSharedTemplate() && (
        <>
          <Button
            variant="secondary"
            onClick={() => handleEdit()}
            startIcon={<Icon icon={ICONS.PENCIL} />}
          >
            Edit
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowShareModal(true)}
            startIcon={<Icon icon={ICONS.SHARE} />}
          >
            Share
          </Button>
          <Button
            variant="secondary"
            className="delete-btn"
            onClick={() => setShowDeleteModal(true)}
            startIcon={<Icon icon={ICONS.TRASH} />}
          >
            Delete
          </Button>
        </>
      )}
      {isSharedTemplate() && (
        <Button
          variant="secondary"
          onClick={() => setShowUnsubscribeDialog(true)}
          startIcon={<Icon icon={ICONS.TRASH} />}
        >
          Unsubscribe
        </Button>
      )}
    </div>
  );

  return (
    <Box className="template-card-box" onClick={() => onTemplateClick?.()}>
      {showHoverButtons && hoverButtons && hoverButtons()}
      <div className="chip-wrapper">
        {isSharedTemplate() && (
          <Chip
            className="shared-template-chip"
            size="medium"
            label="Shared With You"
          />
        )}
        {hasUnpublishedChanges && (
          <Chip
            className="unpublished-changes-chip"
            size="medium"
            label="Unpublished Changes"
          />
        )}
      </div>
      <Stack direction="column" justifyContent="center" spacing={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          className={headerClasses}
        >
          {preview}
          <img
            src={`${envConfig?.s3Url}/organizations/${
              isGlobalTemplate ? DEFAULT_ORGANIZATION : templateOrgId
            }/giving-form/${templateId}/preview-image.jpg?cache=${new Date().getTime()}`}
            className="preview-image"
            alt=""
            onError={(event) => {
              // eslint-disable-next-line no-param-reassign
              event.currentTarget.className = 'preview-image-missing';
            }}
          />
        </Box>
        <Box padding={1.5} className="template-preview-content">
          <Stack direction="column" spacing={0.5}>
            <Text variant="h3">{templateName}</Text>
            {templateDescription && (
              <Text variant="caption" className="template-description-text">
                {templateDescription}
              </Text>
            )}
          </Stack>
        </Box>
      </Stack>

      <DeleteTemplateModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />

      <ShareTemplateModal
        open={showShareModal}
        onClose={() => setShowShareModal(false)}
        selectedTemplate={templateId}
      />

      <UnsubscribeFromShareDialog
        open={showUnsubscribeDialog}
        onConfirm={() => handleUnsubscribe()}
        onClose={() => setShowUnsubscribeDialog(false)}
      />
    </Box>
  );
};

export default TemplatePreviewCard;
