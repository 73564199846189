import styled from '@emotion/styled';

export const ScaffoldingStyled = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 2000 !important;
  background-color: white;
  display: flex;
  iframe {
    flex-grow: 1;
    border: 0px solid transparent !important;
  }
`;
